import Vue from 'vue';
import Buefy from 'buefy';
import VueScrollTo from 'vue-scrollto';
import VueMeta from 'vue-meta';
import App from './App.vue';

import 'buefy/dist/buefy.css';

Vue.use(VueMeta);

Vue.config.productionTip = false;

Vue.use(Buefy);
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: [0.25, 0.1, 0.08, 1.01],
  offset: -100,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

new Vue({
  render: (h) => h(App),
}).$mount('#app');
