<template>
  <div id="app" :style="cssProps">
    <!-- NavBar -->
    <b-navbar
      transparent
      :class="{ 'soilmates-navbarb friemels': pageType == 2 }"
      v-if="pageType === 2"
    >
      <template slot="brand">
        <b-navbar-item href="#home" v-scroll-to="'#home'">
          <div alt="Soilhoveniers Logo" class="navbar-logob" v-if="pageType == 2" />
        </b-navbar-item>
      </template>
      <template slot="start">
        <div class="columns is-vcentered navbar-columns" v-if="pageType == 2">
          <b-navbar-item
            class="column"
            href="#categories"
            v-scroll-to="'#categories'"
            :class="{ 'soilmates-active': activeBlock == 2 }"
          >
            Over
          </b-navbar-item>
          <b-navbar-item
            class="column"
            href="#werkwijze"
            v-scroll-to="'#werkwijze'"
            :class="{ 'soilmates-active': activeBlock == 3 }"
          >
            Werkwijze
          </b-navbar-item>
          <b-navbar-item
            class="column"
            href="#meetthemate"
            v-scroll-to="'#meetthemate'"
            :class="{ 'soilmates-active': activeBlock == 4 }"
          >
            Meet the Soilmate
          </b-navbar-item>
          <b-navbar-item
            class="column elevated-z"
            href="#klantervaringen"
            v-scroll-to="'#klantervaringen'"
            :class="{ 'soilmates-active': activeBlock == 5 }"
          >
            Klantervaringen
          </b-navbar-item>
          <b-navbar-item
            class="column elevated-z"
            href="#contact"
            v-scroll-to="'#contact'"
            :class="{ 'soilmates-active': activeBlock == 6 }"
          >
            Contact
          </b-navbar-item>
          <div class="sun-big" v-if="pageType == 3" />
          <div class="boom-sun-big" v-if="pageType == 3" />
        </div>
      </template>
    </b-navbar>

    <transition name="fade">
      <b-navbar
        transparent
        fixed-top
        v-if="activeMenu"
        :class="{ 'soilmates-navbar': pageType == 0, 'soilmates-navbarb friemels': pageType == 2 }"
      >
        <template slot="brand">
          <b-navbar-item href="#home" v-scroll-to="'#home'">
            <div alt="Soilmates Logo" class="navbar-logo" v-if="pageType == 0" />
            <div alt="Soilhoveniers Logo" class="navbar-logob" v-if="pageType == 2" />
          </b-navbar-item>
        </template>
        <template slot="start">
          <div class="columns is-vcentered navbar-columns" v-if="pageType == 0">
            <b-navbar-item
              class="column"
              href="#categories"
              v-scroll-to="'#categories'"
              :class="{ 'soilmates-active': activeBlock == 3 }"
            >
              Missie
            </b-navbar-item>
            <b-navbar-item
              class="column"
              href="#meetthemate"
              v-scroll-to="'#meetthemate'"
              :class="{ 'soilmates-active': activeBlock == 4 }"
            >
              Achtergrond
            </b-navbar-item>
            <b-navbar-item
              class="column"
              href="#klantervaringen"
              v-scroll-to="'#klantervaringen'"
              :class="{ 'soilmates-active': activeBlock == 5 }"
            >
              Samenwerken
            </b-navbar-item>
            <b-navbar-item
              class="column"
              href="#contact"
              v-scroll-to="'#contact'"
              :class="{ 'soilmates-active': activeBlock == 6 }"
            >
              Contact
            </b-navbar-item>
          </div>
          <div class="columns is-vcentered navbar-columns elevated-z" v-if="pageType == 2">
            <b-navbar-item
              class="column"
              href="#categories"
              v-scroll-to="'#categories'"
              :class="{ 'soilmates-active': activeBlock == 2 }"
            >
              Over
            </b-navbar-item>
            <b-navbar-item
              class="column"
              href="#werkwijze"
              v-scroll-to="'#werkwijze'"
              :class="{ 'soilmates-active': activeBlock == 3 }"
            >
              Werkwijze
            </b-navbar-item>
            <b-navbar-item
              class="column"
              href="#meetthemate"
              v-scroll-to="'#meetthemate'"
              :class="{ 'soilmates-active': activeBlock == 4 }"
            >
              Meet the Soilmate
            </b-navbar-item>
            <b-navbar-item
              class="column elevated-z"
              href="#klantervaringen"
              v-scroll-to="'#klantervaringen'"
              :class="{ 'soilmates-active': activeBlock == 5 }"
            >
              Klantervaringen
            </b-navbar-item>
            <b-navbar-item
              class="column elevated-z"
              href="#contact"
              v-scroll-to="'#contact'"
              :class="{ 'soilmates-active': activeBlock == 6 }"
            >
              Contact
            </b-navbar-item>
          </div>
        </template>
      </b-navbar>
    </transition>

    <!-- Header -->

    <section
      :class="{ hero: pageType == 0, hero2: pageType == 2 }"
      class="soilmates-background is-fullheight"
      id="home"
    >
      <div :class="{ 'hero-body': pageType == 0, 'hero-body2': pageType == 2 }">
        <div class="container">
          <div alt="Soilmates Logo" class="navbar-logo-full" v-if="pageType == 0" />
          <div alt="Soilmates Logo" class="navbar-logo-fullb" v-if="pageType == 2" />
          <h1 class="title" v-if="pageType == 0">Connecting People in Nature</h1>
          <h1 class="titleb" v-if="pageType == 2">Natuur bij jou aan huis</h1>
          <h2 class="subtitle" v-if="pageType == 0"></h2>
          <!-- <b-button
            v-if="pageType == 0"
            class="agenda-button"
            @click="isImageModalActive = true"
            type="is-light"
            ><strong>Buitenweekend Ardennen: </strong>4-6 november 2022</b-button> -->
        </div>
      </div>
    </section>

    <b-modal v-model="isImageModalActive" scroll="keep" v-if="pageType == 0" style="z-index: 101;">
      <div class="soilmates-agenda flyer tile is-parent is-12" @click="openFlyerLink">
        <img
          @click="openFlyerLink"
          src="./assets/Flyer-Soilmates-buitenweekend-1.jpg"
          class="tile is-child is-6 flyer flyer-links"
        />
        <img
          @click="openFlyerLink"
          src="./assets/Flyer-Soilmates-buitenweekend-2.jpg"
          class="tile is-child is-6 flyer flyer-rechts"
        />
      </div>
      <div class="tile is-parent is-12">
        <b-button class="tile is-child flyer-button" @click="openFlyerLink" type="is-dark"
          >Download Flyer</b-button
        >
      </div>
    </b-modal>

    <div class="worm-container">
      <img src="./assets/SVG/worm19.svg" class="worm worm-19" v-if="pageType == 0" />
      <img src="./assets/SVG/worm18.svg" class="worm worm-18" v-if="pageType == 0" />
      <img src="./assets/SVG/worm17.svg" class="worm worm-17" v-if="pageType == 0" />
      <img src="./assets/SVG/worm16.svg" class="worm worm-16" v-if="pageType == 0" />
      <img src="./assets/SVG/worm15.svg" class="worm worm-15" v-if="pageType == 0" />
      <img src="./assets/SVG/worm14.svg" class="worm worm-14" v-if="pageType == 0" />
      <img src="./assets/SVG/worm13.svg" class="worm worm-13" v-if="pageType == 0" />
      <img src="./assets/SVG/worm12.svg" class="worm worm-12" v-if="pageType == 0" />
      <img src="./assets/SVG/worm11.svg" class="worm worm-11" v-if="pageType == 0" />
      <img src="./assets/SVG/worm10.svg" class="worm worm-10" v-if="pageType == 0" />
      <img src="./assets/SVG/worm9.svg" class="worm worm-9" v-if="pageType == 0" />
      <img src="./assets/SVG/worm8.svg" class="worm worm-8" v-if="pageType == 0" />
      <img src="./assets/SVG/worm7.svg" class="worm worm-7" v-if="pageType == 0" />
      <img src="./assets/SVG/worm6.svg" class="worm worm-6" v-if="pageType == 0" />
      <img src="./assets/SVG/worm5.svg" class="worm worm-5" v-if="pageType == 0" />
      <img src="./assets/SVG/worm4.svg" class="worm worm-4" v-if="pageType == 0" />
      <img src="./assets/SVG/worm3.svg" class="worm worm-3" v-if="pageType == 0" />
      <img src="./assets/SVG/worm2.svg" class="worm worm-2" v-if="pageType == 0" />
      <img src="./assets/SVG/worm1.svg" class="worm worm-1" v-if="pageType == 0" />
    </div>

    <section class="section-category2" id="categories" v-if="pageType == 2">
      <div class="klantervaringen-brown">
        <div class="columns categories-columns">
          <div class="column categories-text categories-fix2">
            <img src="./assets/zon.svg" class="column sun-small" />
            <div class="column categories-text ">
              Ik wil in mijn tuin een plekje creëren waar ik in de zon kan zitten.
            </div>
            <div class="column categories-text">
              Ik kom niet toe aan het onderhoud en zoek iemand die mijn tuin kan verzorgen.
            </div>
          </div>
          <div class="column categories-text categories-fix2">
            <div class="column boom-small categories-fix" />
            <div class="column categories-text">
              Ik wil dat mijn tuin het hele jaar door mooi en groen blijft.
            </div>
            <div class="column categories-text categories-fix3">
              Ik wil mijn tuin natuurlijker maken, met minder stenen en meer groen.
            </div>
          </div>
        </div>
      </div>
      <div class="klantervaringen-light">
        <div class="boom-big" />
        <h2 class="subtitle3b not-mobile">
          Wat je wens ook is, Soil Hoveniers maakt <br />
          jouw tuin tot een plek waar je kunt <br />
          ontspannen en genieten.<br /><br />

          Je kunt Soilhoveniers inhuren voor<br />
          jouw tuinontwerp, tuinaanleg of tuinonderhoud.<br />
          Samen maken we een natuurlijke tuin die past bij jou.<br /><br />

          Neem contact op voor een gesprek of offerte:<br /><br />

          Soil Hoveniers - Arjen Kooiman<br />
          arjen@soilhoveniers.nl<br />
          +31 6 2892 1152
        </h2>
        <h2 class="subtitle3b mobile">
          Wat je wens ook is, Soil Hoveniers maakt <br />
          jouw tuin tot een plek waar je kunt <br />
          ontspannen en genieten.<br /><br />

          Je kunt Soilhoveniers inhuren voor<br />
          jouw tuinontwerp, tuinaanleg of tuinonderhoud. Samen maken we een natuurlijke tuin die
          past bij jou.<br /><br />

          Neem contact op voor een gesprek of offerte:<br /><br />

          Soil Hoveniers - Arjen Kooiman<br />
          arjen@soilhoveniers.nl<br />
          +31 6 2892 1152
        </h2>
      </div>
      <div class="klantervaringen-imgb box-arjen-staand2" style="height: 500px;">
        <img src="./assets/Eetbare klimplanten.jpg" class="about-pics arjen-links2" style="" />
      </div>
      <div class="klantervaringen-light">
        <h2 class="subtitle4b" style="text-align: center;">
          Voor iedereen met een grote of kleine tuin in omgeving Den Haag,<br />verzorgt Soil
          Hoveniers tuinontwerp, tuinaanleg en tuinonderhoud.
        </h2>
        <div class="columns wat-columns">
          <div class="column categories-text">
            <h1 class="subtitle4bh1" style="margin-bottom: 20px;">Tuinanalyse</h1>
            Een gezonde bodem is de basis voor een levende tuin.<br /><br />
            Daarom maken we graag een analyse van de bodem van jouw tuin. We nemen grondmonsters af,
            waarna we de bovenste laag van de tuinaarde analyseren: hoe staat het met het
            bodemleven, de voedingsstoffen en de zuurgraad?<br /><br />
            Op basis van de uitkomsten geven we je advies over bijvoorbeeld bemesting, de
            verbetering van de structuur van je bodem of het afvoeren van overtollig water.
          </div>
          <div class="column categories-text">
            <h1 class="subtitle4bh1" style="margin-bottom: 20px;">Tuinontwerp</h1>
            Wil je je tuin helemaal omgooien? Met meer groen, een vijver, nieuwe schuttingen? Dan
            maakt Soil Hoveniers graag een tuinontwerp, afgestemd op jouw wensen. Ga naar
            <a href="#contact" v-scroll-to="'#contact'">contactgegevens</a>.
          </div>
        </div>
      </div>
      <div class="klantervaringen-imgb box-arjen-staand2" style="height: 500px;">
        <img src="./assets/Vijver.jpg" class="about-pics arjen-links2" />
      </div>
      <div class="klantervaringen-light">
        <div class="columns wat-columns">
          <div class="column categories-text">
            <h1 class="subtitle4bh1" style="margin-bottom: 20px;">Tuinaanleg</h1>
            Soil Hoveniers kan je hele tuin aanleggen. Maar ook als je een klein stukje van je tuin
            wilt veranderen, kunnen wij dit voor je doen.<br /><br />
            Enkele voorbeelden van ons werk:
            <ul class="soilhoveniers-ul">
              <li>
                Planten en bloemen plaatsen, passend bij de plek in je tuin en de bodemkwaliteit
              </li>
              <li>Bomen planten</li>
              <li>Schuttingen neerzetten</li>
              <li>Pergola bouwen</li>
              <li>Groen dak leggen, bijvoorbeeld op je schuur</li>
              <li>Stenen paadje of terras leggen</li>
              <li>Gras leggen</li>
              <li>Vijver aanleggen</li>
              <li>
                Je bestaande tuin natuurlijker maken, door stenen eruit te halen en planten erin te
                zetten
              </li>
              <li>
                Water opvangen, zodat het je tuin in kan lopen en je tuin minder kwetsbaar is
                tijdens droogte
              </li>
            </ul>
          </div>
          <div class="column categories-text">
            <h1 class="subtitle4bh1" style="margin-bottom: 20px;">Tuinonderhoud</h1>
            Wil je het hele jaar door genieten van een mooie tuin, maar kom je niet toe aan het
            onderhoud? Of weet je niet goed hoe je dat het beste kunt doen? Vraag ons dan om je tuin
            te onderhouden. Regelmatig komen we langs voor de nodige klussen.<br /><br />Denk aan:
            <ul class="soilhoveniers-ul">
              <li>Bomen en planten snoeien</li>
              <li>Gras maaien</li>
              <li>Nieuwe planten plaatsen</li>
              <li>Bemesten</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="klantervaringen-imgb box-arjen-staand2" style="height: 500px;">
        <img src="./assets/Varenstraat-tijdens.jpg" class="about-pics arjen-links2" />
      </div>
    </section>

    <section class="section-werkwijze" id="werkwijze" v-if="pageType == 2">
      <!-- <div class="klantervaringen-brown">
        <h2 class="subtitle3b">
          Ik wil in mijn tuin een plekje creëren waar ik in de zon kan zitten. Ik kom niet toe aan
          het onderhoud en zoek iemand die mijn tuin kan verzorgen. Ik wil dat mijn tuin het hele
          jaar door mooi en groen blijft. Ik wil mijn tuin natuurlijker maken, met minder stenen en
          meer groen.
        </h2>
      </div> -->
      <div class="klantervaringen-lightish2">
        <h1 class="subtitle4bh1">
          Werkwijze
        </h1>
        <h2 class="subtitle3b2">
          Natuurlijk en divers
        </h2>
        <br />Soil Hoveniers houdt van tuinen die groen zijn en veel verschillende planten en
        bloemen bevatten. Zo wordt de tuin een aantrekkelijke plek voor jou, maar ook voor insecten
        en vogels.<br />
        Ons uitgangspunt is dat natuur en leven zorgen voor gezondheid en een prettig gevoel. Daarom
        zoeken we naar een goede balans tussen statische materialen, zoals steen, en levende
        materialen, zoals planten en bomen, in jouw tuin.<br />
        Ook in een kleine tuin zijn er veel mogelijkheden voor een grote diversiteit aan groen. Soil
        Hoveniers denkt daarin graag met je mee.<br />
        <h2 class="subtitle3b2">
          Samenwerken
        </h2>
        <br />
        Soil Hoveniers werkt het liefst met mensen, we zijn graag in contact met jou. We zijn
        geïnteresseerd in jouw wensen en verzorgen maatwerk. Met onze kennis, kunde, gereedschappen
        en tijd nemen we jou het zware en specialistische werk uit handen en bezorgen we jou een
        mooi eindproduct: een natuurlijke tuin waar jij blij van wordt.<br />
        <h2 class="subtitle3b2">
          Soil = bodem
        </h2>
        <br />
        Voor Soil Hoveniers is de bodem van wezenlijk belang: een gezonde bodem is de basis voor een
        levende tuin. Soil Hoveniers sluit zich daarom aan bij de uitgangspunten van vakgroep Wilde
        Weelde:
        <ul class="soilhoveniers-ul" style="margin-top: 20px;">
          <li>Bodem is de basis</li>
          <li>Planten maken de tuin</li>
          <li>Biodiversiteit</li>
          <li>Gifvrij</li>
          <li>Hergebruik van materialen, planten, stenen</li>
          <li>Duurzaam materiaal gebruik</li>
          <li>Klimaatbestendig</li>
        </ul>
      </div>
      <div class="klantervaringen-nice">
        ‘Ik word blij van levende tuinen!<br />
        Waar het aangenaam is voor mensen,<br />
        maar ook voor vogels en insecten. Ik<br />
        houd van een gezonde bodem, veel groen<br />
        en natuurlijke materialen. Dat zijn<br />
        belangrijke uitgangspunten voor mij<br />
        als hovenier.’
      </div>
      <div class="klantervaringen-imgb box-arjen-staand3b">
        <img src="./assets/arjenintuin.jpg" class="about-pics move-img-up3 arjen-links2" />
      </div>
      <div class="sun-big2" v-if="pageType == 2" />
    </section>

    <section class="section-werkwijze" id="meetthemate" v-if="pageType == 2">
      <div class="klantervaringen-lightish3">
        <h1 class="subtitle4bh1">
          Arjen Kooiman is Soil Hoveniers
        </h1>
        <br />
        <h2 class="subtitle3b2">
          Mijn eigen tuin
        </h2>
        <br />‘Mijn interesse in tuinen ontstond in 2015. Ik verhuisde naar Den Haag, naar een huis
        met een tuin van 55 m2. De tuin was nog helemaal leeg. In de loop van de jaren vulde ik de
        kale meters. Ik experimenteerde met planten, kippen, compost en hergebruik van
        materialen.<br />
        In die jaren trok ik ook steeds vaker de natuur in. Ik ga bijvoorbeeld graag wildkamperen.
        En wildplukken: in de vrije natuur zoek ik naar eetbare planten.<br />
        Ik ben heel enthousiast over de natuur. Elke keer als in om me heen kijk, zie ik zoveel
        moois en natuurlijks en ontdek ik nieuwe planten, paddenstoelen en insecten.’
        <h2 class="subtitle3b2">
          Mijn opleiding en werkervaring
        </h2>
        <br />
        ‘Ik volgde de vaktechnische hoveniersopleiding en sinds 2019 werk ik als hovenier.<br />
        Ik werk graag met mijn handen! Iets moois ontwerpen, de kennis van planten en het
        onderhouden van de natuur maken het hoveniersvak aantrekkelijk en afwisselend. Naast natuur,
        houd ik ook van mensen. Ik ben geïnteresseerd in wat hen drijft en luister naar hun wensen.’
      </div>
    </section>

    <section class="section-werkwijze" id="klantervaringen" v-if="pageType == 2">
      <div class="klantervaringen-lightish4">
        <h1 class="subtitle4bh1">
          Klantervaringen
        </h1>
        <br />
        <h2 class="subtitle3b2">
          Paul Andriessen
        </h2>
        <br /><span style="font-style: italic;">
          Als je een tuin hebt die dringend aan goed onderhoud toe is, dan hoop je dat je iemand
          kunt vinden die daar verstand van heeft. Een vent of vrouw die kijk heeft op wat
          noodzakelijk en slim onderhoud is, die een plan heeft voor een goede aanpak en daar een
          passende rekening bij legt. Kortom, je zoekt een hovenier die authentiek, oprecht,
          creatief en deskundig is. Zo'n hovenier is moeilijk te vinden, dat weet ik uit ervaring.
          We hebben ons rot gezocht, in stad en ommeland. Hoveniers genoeg, maar meestal stuitten we
          op snelle jongens. Grote stappen, snel klaar en weg. Frusterend. En toen kruiste Arjen
          Kooiman ons pad. Hij kwam, zag en wist wat er moest gebeuren. Hij maakte een plan en
          begroting. En verdomd, hij deed met verve wat hij had beloofd. Iemand die dat doet, levert
          kwaliteit. Dat kan niet anders. Hij kwam op tijd, werkte hard en vasthoudend, in weer en
          wind. Hij deed het met liefde en plezier. Tijdens de koffiepauze bleek ook zijn
          veelzijdigheid op tal van andere terreinen. Arjen Kooiman is een fijn mens en een
          voortreffelijke hovenier. Ik kan hem oprecht en met reden aanbevelen.</span
        >
      </div>
      <div class="klantervaringen-lightish4b">
        <div class="tile is-ancestor">
          <div class="tile is-vertical is-12">
            <div class="tile">
              <div class="soilimg tile is-child">
                <img class="" src="./assets/Andriessen.jpg" /><span class="soiltag"
                  >Paul Andriessen</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="klantervaringen-lightish4" style="padding-top: 20px;">
        <h2 class="subtitle3b2">
          Varenstraat in Monster
        </h2>
        <br /><span style="font-style: italic;">
          Arjen heeft ons van begin tot eind geholpen een prachtig paradijsje van onze, voorheen
          tamelijk sfeerloze, achtertuin te maken. Arjen dacht mee met het ontwerp, luisterde goed
          naar onze wensen en kwam met de meest creatieve en duurzame oplossingen.<br /><br />
          Vanaf het begin wist ik: of Arjen doet onze tuin, of we doen het niet. Ik was zo stellig
          omdat ik weet wat Arjen z’n filosofie is en hoe hij in het leven staat. Hij houdt van
          kwaliteit en dicht bij de natuur leven. Het gebruik van de natuurlijke materialen in onze
          tuin maakt het een gezellig en levendig geheel. Overal zitten kleine (eetbare)
          verrasinkjes. Het schommeltje maakt het helemaal af. Arjen heeft passie voor wat hij doet.
          Dat straalt ervan af!</span
        >
      </div>
      <div class="klantervaringen-lightish4b">
        <div class="tile is-ancestor">
          <div class="tile is-vertical is-12">
            <div class="tile">
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/Varenstraat-voor.jpg" /><span class="soiltag"
                    >Varenstraat voor</span
                  >
                </div>
              </div>
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/Varenstraat-na2.jpg" /><span class="soiltag"
                    >Varenstraat na</span
                  >
                </div>
              </div>
            </div>
            <div class="tile">
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/Schommel-pergola.jpg" /><span class="soiltag"
                    >Schommel</span
                  >
                </div>
              </div>
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/WhatsApp-Image-2022-02-04-at-09.35.48.jpg" /><span
                    class="soiltag"
                    >Varenstraat na</span
                  >
                </div>
              </div>
            </div>

            <div class="tile">
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/A v Solmsstr voor.jpg" /><span
                    class="soiltag"
                    >A. van Solmsstraat voor</span
                  >
                </div>
              </div>
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/A v solmsstr na.jpg" /><span
                    class="soiltag"
                    >A. van Solmsstraat na</span
                  >
                </div>
              </div>
            </div>

            <div class="tile">
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/voortuin a v solmsstr voor.jpg" /><span
                    class="soiltag"
                    >Voortuin A. van Solmsstraat voor</span
                  >
                </div>
              </div>
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/voortuin a v solmsstr na.jpg" /><span
                    class="soiltag"
                    >voortuin A. van Solmsstraat voor na</span
                  >
                </div>
              </div>
            </div>

            <div class="tile">
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/balkon a v solmsstr voor.jpg" /><span
                    class="soiltag"
                    >Balkon A. van Solmstraat voor</span
                  >
                </div>
              </div>
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/balkon a v solmsstr na.jpg" /><span
                    class="soiltag"
                    >Balkon A. van Solmstraat na</span
                  >
                </div>
              </div>
            </div>

            <div class="tile">
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/voortuin Luts voor.jpg" /><span
                    class="soiltag"
                    >voortuin Luts voor</span
                  >
                </div>
              </div>
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/voortuin Luts na.jpg" /><span
                    class="soiltag"
                    >voortuin Luts na</span
                  >
                </div>
              </div>
            </div>

            <div class="tile">
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/achtertuin Luts voor.jpg" /><span
                    class="soiltag"
                    >Achtertuin Luts voor</span
                  >
                </div>
              </div>
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/achtertuin Luts na.jpg" /><span
                    class="soiltag"
                    >Achtertuin Luts na</span
                  >
                </div>
              </div>
            </div>

            <div class="tile">
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/Cornelis vd Lijnstraat voor.jpg" /><span
                    class="soiltag"
                    >Cornelis vd Lijnstraat voor</span
                  >
                </div>
              </div>
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/Cornelis vd Lijnstraat na.jpg" /><span
                    class="soiltag"
                    >Cornelis vd Lijnstraat na</span
                  >
                </div>
              </div>
            </div>

            <div class="tile">
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/Gentsestr voor.jpg" /><span
                    class="soiltag"
                    >Gentsestraat voor</span
                  >
                </div>
              </div>
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/Gentsestr na.jpg" /><span class="soiltag"
                    >Gentsestraat na</span
                  >
                </div>
              </div>
            </div>

            <div class="tile">
              <div class="tile is-parent is-vertical">
                <div class="soilimg tile is-child">
                  <img
                    class=""
                    src="./assets/hoveniers23/h zwaardecroon containers voor.jpg"
                  /><span class="soiltag">H. Zwaardecroon containers voor</span>
                </div>
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/h zwaardecroon containers na.jpg" /><span
                    class="soiltag"
                    >H. Zwaardecroon containers na</span
                  >
                </div>
              </div>
              <div class="tile is-parent is-vertical">
                <div class="soilimg tile is-child">
                  <img
                    class="arjen-links3"
                    src="./assets/hoveniers23/h zwaardecrstr voor.jpg"
                  /><span class="soiltag">H. Zwaardecroon voor</span>
                </div>
                <div class="soilimg tile is-child">
                  <img class="arjen-links3" src="./assets/hoveniers23/h zwaardecrstr na.jpg" /><span
                    class="soiltag"
                    >H. Zwaardecroon na</span
                  >
                </div>
              </div>
            </div>

            <div class="tile">
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/Loosduinsekade voor.jpg" /><span
                    class="soiltag"
                    >Loosduinsekade voor</span
                  >
                </div>
              </div>
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/Loosduinsekade na.jpg" /><span
                    class="soiltag"
                    >Loosduinsekade na</span
                  >
                </div>
              </div>
            </div>

            <div class="tile">
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/v Boetzelaerlaan voor.jpg" /><span
                    class="soiltag"
                    >van Boetzelaerlaan voor</span
                  >
                </div>
              </div>
              <div class="tile is-parent">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/v Boetzelaerlaan na.jpg" /><span
                    class="soiltag"
                    >van Boetzelaerlaan na</span
                  >
                </div>
              </div>
            </div>

            <div class="tile">
              <div class="tile is-parent is-vertical">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/Bezuidenhoutseweg voor.jpg" /><span
                    class="soiltag"
                    >Bezuidenhoutseweg voor</span
                  >
                </div>
                <div class="soilimg tile is-child">
                  <img class="arjen-links3" src="./assets/hoveniers23/C vd Houtstr voor.jpg" /><span
                    class="soiltag"
                    >C. vd Houtstraat voor</span
                  >
                </div>
              </div>
              <div class="tile is-parent is-vertical">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/Bezuidenhoutseweg na.jpg" /><span
                    class="soiltag"
                    >Bezuidenhoutseweg na</span
                  >
                </div>
                <div class="soilimg tile is-child">
                  <img class="arjen-links3" src="./assets/hoveniers23/c vd Houtstr na.jpg" /><span
                    class="soiltag"
                    >C. vd Houtstraat na</span
                  >
                </div>
              </div>
            </div>

            <div class="tile">
              <div class="tile is-parent is-vertical">
                <div class="soilimg tile is-child">
                  <img
                    class=""
                    src="./assets/hoveniers23/activiteiten/bomen snoeien Jan.jpg"
                  /><span class="soiltag">Bomen snoeien</span>
                </div>
                <div class="soilimg tile is-child">
                  <img
                    class="arjen-links2"
                    src="./assets/hoveniers23/activiteiten/Jan met zaag.jpg"
                  />
                </div>
              </div>
              <div class="tile is-parent is-vertical">
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/activiteiten/bomen snoeien.jpg" />
                </div>
                <div class="soilimg tile is-child">
                  <img class="" src="./assets/hoveniers23/activiteiten/hout en steen.jpg" />
                </div>
                <div class="soilimg tile is-child">
                  <img
                    class="arjen-links2"
                    src="./assets/hoveniers23/activiteiten/vlonder boven vijver.jpg"
                  />
                </div>
              </div>
            </div>

            <div class="tile">
              <div class="tile is-parent is-vertical">
                <div class="soilimg tile is-child">
                  <img
                    class="arjen-links2"
                    src="./assets/hoveniers23/stapelmuurtje voor.jpg"
                  /><span class="soiltag">Stapelmuurtje voor</span>
                </div>
              </div>
              <div class="tile is-parent is-vertical">
                <div class="soilimg tile is-child">
                  <img
                    class="arjen-links2"
                    src="./assets/hoveniers23/activiteiten/stapelmuurtje bouwen copy.jpg"
                  />
                </div>
                <div class="soilimg tile is-child">
                  <img class="arjen-links2" src="./assets/hoveniers23/stapelmuurtje na.jpg" /><span
                    class="soiltag"
                    >Stapelmuurtje na</span
                  >
                </div>
              </div>
            </div>

            <!--

            <div class="tile">
              <div class="soilimg tile is-child">
                <img class="" src="./assets/groen-sedum-dak.jpg" /><span class="soiltag"
                  >Groen sedum dak</span
                >
              </div>
            </div>

            <div class="tile">
              <div class="tile is-parent">
                <img class="" src="./assets/eetbare-klimmers.jpg" />
              </div>
              <div class="tile is-parent">
                <img class="" src="./assets/Kleinfruit-pluktuin.jpg" />
              </div>
            </div>
            <div class="tile">
              <div class="tile is-parent">
                <img class="" src="./assets/Ontspannen.jpg" />
              </div>
              <div class="tile is-parent box-arjen-staand3">
                <img class="arjen-links2" src="./assets/Vijver.jpg" />
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- <div class="klantervaringen-imgb" style="height: 500px;">
        <img src="./assets/hergebruik stoeptegels.jpg" class="about-pics move-img-up" />
      </div> -->
    </section>

    <!-- Contact Info -->
    <section class="section-werkwijze" id="contact" v-if="pageType == 2">
      <div class="klantervaringen-lightish5" style="text-align: center !important;">
        <h1 class="subtitle4bh1">
          Contact
        </h1>
        <br />
        Ben je op zoek naar een betrokken en betrouwbare hovenier in de<br />
        omgeving van Den Haag? Neem dan contact met ons op.<br /><br />
        Soil Hoveniers - Arjen Kooiman<br />
        arjen@soilhoveniers.nl<br />
        +31 6 2892 1152
      </div>
      <div class="klantervaringen-white2">
        <div class="tile is-ancestor">
          <div class="tile is-vertical is-8">
            <div class="tile">
              <div class="tile is-parent box-arjen-staand">
                <img class="arjen-links" src="./assets/arjen-auto.jpg" />
              </div>
            </div>
          </div>
          <div class="tile is-vertical is-4">
            <div class="tile">
              <div class="tile is-parent box-arjen-staand5">
                <img class="arjen-links" src="./assets/Arjen.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-contactb friemels2" v-if="pageType == 2">
      <div class="logo-box">
        <div alt="Soilhoveniers Logo" class="navbar-logo3c" />
        <div alt="Soilhoveniers Logo" class="navbar-logo3d" />
      </div>
      <div class="contact-detailsb friemels">
        <div class="contact-detailsbox">
          <span>Arjen Kooiman</span><span>+31 6 2892 1152 </span><span>arjen@soilhoveniers.nl</span>
        </div>
        <!-- <div class="columns">
          <div class="column">
            <span>Arjen Kooiman</span>
          </div>
          <div class="column">
            <span>+31 6 1840 9549</span>
          </div>
          <div class="column">
            <span>arjen@soilhoveniers.nl</span>
          </div>
        </div> -->
      </div>
    </section>

    <!-- Footer -->
    <footer class="section-footerb friemels" v-if="pageType == 2">
      <div class="columns">
        <div class="column">
          <div class="footer-img2" />
          <strong>KvK: <span>76355802</span></strong>
        </div>
        <div class="column">
          <div class="footer-img3" />
          <strong>IBAN: <span>NL 66 KNAB 0259 8859 67</span></strong>
        </div>
        <div class="column">
          <div class="footer-img4" />
          <strong>BTW: <span>NL003078679B12</span></strong>
        </div>
      </div>
    </footer>

    <section class="section-intro" v-if="pageType == 0">
      <div class="intro-text">
        Natuur en mensen.<br />Vanuit deze twee passies ontstond Soilmates.<br /><br />
        <h2 class="subtitle3">
          Buiten zijn, de natuur ontdekken en primitief leven.<br />Ondernemend en samen.
          <br /><br />Scroll verder en ontdek wie ik ben, <br />wat Soilmates betekent en hoe we
          kunnen samenwerken.
        </h2>
      </div>
    </section>

    <!-- <section class="section-contact2" v-if="pageType == 0">
      <div class="contact-details2">
        <span>Arjen Kooiman</span><span>+31 6 2892 1152 </span><span>arjen@soilmates.nl</span>
      </div>
    </section> -->

    <section class="section-category3" id="categories" v-if="pageType == 0">
      <h1 class="title4">Spreekt dit je aan?</h1>
      <h2 class="subtitle4">
        Dan kom ik graag met je in contact:
      </h2>

      <!-- <div class="klantervaringen-brown"> -->
      <div class="columns categories-columns">
        <div class="column categories-text categories-fix2">
          <!-- <img src="./assets/zon.svg" class="column sun-small" /> -->
          <div class="column categories-text3">
            <img
              src="./assets/SVG/icoon_hike_small.svg"
              style="width: 65px;margin-top:10px;"
              class="tile-img2"
            />
            Om samen de natuur in te gaan: ik neem jou (en je vrienden) mee om te gaan wildkamperen.
          </div>
          <div class="column categories-text3">
            <img
              src="./assets/SVG/icoon_kampverblijf_small.svg"
              style="width: 150px;margin-top:45px;"
              class="tile-img2"
            />
            Om je verder te helpen: met de bouw van je duurzame huis of de ontwikkeling van je
            primitieve camping.
          </div>
        </div>
        <div class="column categories-text categories-fix2">
          <!-- <div class="column boom-small categories-fix" /> -->
          <div class="column categories-text3">
            <img
              src="./assets/SVG/icoon_houtbewerking_small.svg"
              style="width: 100px;"
              class="tile-img2"
            />
            Om samen te werken: bij het onderhoud van een stuk bos of de aanleg van je
            natuurvriendelijke tuin.
          </div>
          <!-- <img src="./assets/SVG/icoon_kampvuur_small.svg" class="tile-img" /> -->
          <div class="column categories-text3 categories-fix3">
            <img
              src="./assets/SVG/icoon_bezinning_small.svg"
              style="width: 50px;"
              class="tile-img2"
            />
            Of gewoon om elkaar te inspireren.
          </div>
        </div>
      </div>
      <div class="worm-container-between fifth">
        <img src="./assets/SVG/worm3.svg" class="worm worm-3" />
        <img src="./assets/SVG/worm5.svg" class="worm worm-5" />
        <img src="./assets/SVG/worm9.svg" class="worm worm-9" />
        <img src="./assets/SVG/worm13.svg" class="worm worm-13" />
      </div>
      <div class="contact-details2">
        <span>Arjen Kooiman</span><span>+31 6 2892 1152 </span><span>arjen@soilmates.nl</span>
      </div>
    </section>

    <!-- Categories -->
    <section class="section-category" id="categories" v-if="pageType == 0">
      <div class="tile is-ancestor">
        <div class="tile is-vertical is-4 is-about-category">
          <div class="box-img">
            <img class="about-pics" src="./assets/soilmates23/buiten slapen.jpg" />
          </div>
          <div class="box-cat-title">
            <p class="title">NATUUR</p>
          </div>
          <div class="box-cat-content">
            <p>
              In mijn studietijd reisde ik met een groep studenten naar Tsjechië voor een Back to
              Basic kamp. Daar begon mijn liefde voor de natuur. We sliepen buiten onder
              overhangende rotsen. Met elkaar ontdekten we de natuur. Dit is vet, dacht ik! Daarna
              ging ik steeds vaker wildkamperen. Met vrienden, of alleen. Onder een zeiltje slapen,
              zelf vuur maken, wildplukken. Ik heb een drang naar het primitieve. In de natuur valt
              veel opsmuk weg en kan ik makkelijker mezelf zijn. Ik voel me autonoom, kan mijn eigen
              gang gaan en wordt niet tegengehouden door gebrek aan comfort.
            </p>
          </div>
        </div>
        <div class="tile is-vertical is-4 is-about-category">
          <div class="box-img">
            <img class="about-pics" src="./assets/soilmates23/hike met vrienden.jpg" />
          </div>
          <div class="box-cat-title">
            <p class="title">MENSEN</p>
          </div>
          <div class="box-cat-content">
            <p>
              Ik ben graag in contact met mensen. Ik zoek naar echt contact: wie ben jij en zie je
              mij? Het liefst ga ik samen met anderen aan de slag, iets creëren: een vuur, een tuin,
              een huis. Daar krijg ik energie van.
            </p>
          </div>
        </div>
        <div class="tile is-vertical is-4 is-about-category">
          <div class="box-img">
            <img class="about-pics" src="./assets/soilmates23/Samenwerken zweethut maken.jpg" />
          </div>
          <div class="box-cat-title">
            <p class="title">IN BEWEGING</p>
          </div>
          <div class="box-cat-content">
            <p>
              Hoe kan ik verder? Die vraag stel ik mijzelf altijd. Ik ben continu in beweging.
              ‘What’s next?’, dacht ik nadat ik een paar keer onder een zeiltje had geslapen. Dus
              ging ik kamperen zonder zeiltje, en zonder vuur mee te nemen. Ik liet mijn eten thuis
              en ging zelf op zoek naar eten. Zo ontdekte ik het wildplukken. Ik houd van nieuwe
              dingen uitproberen. Ik wandel buiten de gebaande paden, letterlijk en figuurlijk. Niet
              weten waar ik uitkom, dat vind ik spannend, maar ook fijn.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Toolbox -->
    <section class="section-toolbox" id="toolbox" v-show="pageType == 5">
      <h1 class="title2 toolbox-title">Toolbox</h1>
      <h2 class="subtitle2 toolbox-subtitle">
        Een greep uit de tools en activiteiten van Soilmates
      </h2>

      <div class="is-toolbox-wrapper">
        <div class="tile is-ancestor">
          <div class="tile is-multiline is-mobile is-parent is-3">
            <div class="tile is-vertical is-child is-toolbox">
              <img src="./assets/SVG/icoon_spelelementen_small.svg" class="tile-img" />
              <span class="tile-title">spelelementen</span>
            </div>
          </div>
          <div class="tile is-parent is-3">
            <div class="tile is-multiline is-mobile is-vertical is-child is-toolbox">
              <img
                src="./assets/SVG/icoon_groepsgesprek_small.svg"
                class="tile-img"
                style="width: 90px;"
              />
              <span class="tile-title">groepsgesprek/<br />discussie</span>
            </div>
          </div>
          <div class="tile is-parent is-3">
            <div class="tile is-vertical is-child is-toolbox">
              <img
                src="./assets/SVG/icoon_kokenopvuur_small.svg"
                class="tile-img"
                style="width: 170px;"
              />
              <span class="tile-title">koken op vuur</span>
            </div>
          </div>
          <div class="tile is-parent is-3">
            <div class="tile is-vertical is-child is-toolbox">
              <img
                src="./assets/SVG/icoon_buitenweekend_small.svg"
                class="tile-img"
                style="width: 90px;"
              />
              <span class="tile-title">buitenweekend<br />met thema</span>
            </div>
          </div>
        </div>
        <div class="tile is-ancestor">
          <div class="tile is-parent is-3">
            <div class="tile is-vertical is-child is-toolbox">
              <img
                src="./assets/SVG/icoon_zintuigenactiveren_small.svg"
                class="tile-img"
                style="width: 160px;"
              />
              <span class="tile-title">zintuigen activeren</span>
            </div>
          </div>
          <div class="tile is-parent is-3">
            <div class="tile is-vertical is-child is-toolbox">
              <img src="./assets/SVG/icoon_bezinning_small.svg" class="tile-img" />
              <span class="tile-title">bezinnings- & reflectiemomenten</span>
            </div>
          </div>
          <div class="tile is-parent is-3">
            <div class="tile is-vertical is-child is-toolbox">
              <img src="./assets/SVG/icoon_hike_small.svg" class="tile-img" />
              <span class="tile-title">meerdaagse hike</span>
            </div>
          </div>
          <div class="tile is-parent is-3">
            <div class="tile is-vertical is-child is-toolbox">
              <img
                src="./assets/SVG/icoon_elementen_small.svg"
                style="width: 150px !important;"
                class="tile-img"
              />
              <span class="tile-title">blootstaan aan elementen</span>
            </div>
          </div>
        </div>
        <div class="tile is-ancestor">
          <div class="tile is-parent is-3">
            <div class="tile is-vertical is-child is-toolbox">
              <img src="./assets/SVG/icoon_kampvuur_small.svg" class="tile-img" />
              <span class="tile-title">kampvuur</span>
            </div>
          </div>
          <div class="tile is-parent is-3">
            <div class="tile is-vertical is-child is-toolbox">
              <img src="./assets/SVG/icoon_houtbewerking_small.svg" class="tile-img" />
              <span class="tile-title">houtbewerking</span>
            </div>
          </div>
          <div class="tile is-parent is-3">
            <div class="tile is-vertical is-child is-toolbox">
              <img src="./assets/SVG/icoon_etenuitnatuur_small.svg" class="tile-img" />
              <span class="tile-title">eten uit natuur</span>
            </div>
          </div>
          <div class="tile is-parent is-3">
            <div class="tile is-vertical is-child is-toolbox">
              <img
                src="./assets/SVG/icoon_kampverblijf_small.svg"
                style="width: 190px;"
                class="tile-img"
              />
              <span class="tile-title">kampverblijf bouwen</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Meet the Mate -->
    <section class="section-meet" id="meetthemate" v-if="pageType == 0">
      <h1 class="title2">Soilmates is Arjen Kooiman</h1>

      <!-- 2 kolommen, 1e tekst, 2e arrangment: 2 columns, 2e row een plaatje -->

      <div class="columns meetthemate-columns">
        <div class="column meetthemate-text">
          In 2019 startte ik met Soilmates. Ik wilde me verbinden met de natuur en met mensen, en
          daar mijn werk van maken. Ik organiseer op aanvraag buitenweekenden en werk samen met
          mensen aan projecten of klussen die te maken hebben met natuur, primitief en
          zelfvoorzienend leven en natuurvriendelijk bouwen. Daarnaast startte ik in 2020 met
          <a href="https://www.soilhoveniers.nl">Soil Hoveniers</a>, mijn eigen hoveniersbedrijf.<br /><br />
        </div>

        <div class="column">
          <div class="columns">
            <!-- <div class="meetthemate-column">
              <div class="box-arjen-staand">
                <img src="./assets/FotoArjen_links_klein.jpg" class="arjen-links" />
              </div>
            </div> -->
            <!-- <div class="meetthemate-column meetthemate-column-right">
              <div class="box-arjen-staand">
                <img src="./assets/FotoArjen_rechts_klein.jpg" class="arjen-rechts" />
              </div>
            </div> -->
          </div>
          <div class="columns">
            <div class="meetthemate-column-bottom">
              <div class="box-arjen-liggend">
                <img src="./assets/soilmates23/portret.jpg" class="arjen-onder" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tile is-ancestor">
        <div class="tile is-vertical is-4 is-about-category">
          <div class="box-img2" style="border-right: 0px solid white;">
            <a href="https://www.soilhoveniers.nl">
              <img class="about-pics" src="./assets/soilmates23/IMG_3122.jpg"
            /></a>
          </div>
        </div>
        <div class="tile is-vertical is-4 is-about-category">
          <div class="box-img2">
            <img class="about-pics" src="./assets/soilmates23/ontdekken in het bos.jpg" />
          </div>
        </div>
        <div class="tile is-vertical is-4 is-about-category">
          <div class="box-img2">
            <img class="about-pics" src="./assets/soilmates23/IMG_2469.jpg" />
          </div>
        </div>
      </div>
    </section>
    <section class="section-meet2" id="meetthemate2" v-if="pageType == 0">
      <div class="klantervaringen-blue2">
        <h2 class="title2">
          Bodem en vrienden
        </h2>
        <h2 class="subtitle3">
          In Soilmates komen natuur en mensen samen:
          <ul class="soilmates-ul2" style="margin-top: 20px;">
            <li>soil staat voor aarde, bodem.</li>
            <li>mates staat voor maatjes</li>
          </ul>
          <br />Soil – de bodem – is de basis voor planten, voor het leven van dier en mens. De
          bodem zelf zit vol leven: allerlei diertjes en organismen werken samen om tot een
          vruchtbare bodem te komen. Ik houd van de bodem. Het staat symbool voor het leven: als
          mensen hebben we allemaal onze eigenheid, maar we hebben elkaar ook nodig om samen iets
          moois, iets vruchtbaars te creëren. Mates – maatjes – staat voor echt contact, diepe
          verbinding. Wie is de ander? Echt contact betekent voor mij: prikkelende vragen stellen.
          Ik houd ervan om anderen aan mij te binden en door hen geïnspireerd te worden. Ik ben
          nieuwsgierig naar de ander en houd van mensen met een positieve levensinstelling.
        </h2>
      </div>
    </section>

    <!-- Customer Experience -->
    <section class="section-klantervaringen" id="klantervaringen" v-if="pageType == 0">
      <h1 class="title2"></h1>
      <div class="tile is-ancestor" style="margin-bottom: 0;">
        <div class="tile is-vertical is-4 is-about-category">
          <div class="box-img2" style="border-right: 0px solid white;">
            <a href="https://www.soilhoveniers.nl">
              <img class="about-pics" src="./assets/soilmates23/kruiden plukken.jpg"
            /></a>
          </div>
        </div>
        <div class="tile is-vertical is-4 is-about-category">
          <div class="box-img2">
            <img class="about-pics" src="./assets/soilmates23/Koken op vuur.jpg" />
          </div>
        </div>
        <div class="tile is-vertical is-4 is-about-category">
          <div class="box-img2">
            <img class="about-pics" src="./assets/soilmates23/B45A0869.jpg" />
          </div>
        </div>
      </div>
      <div class="klantervaringen-white">
        <h2 class="title3">
          Samenwerken met mij? Graag!
        </h2>
        <br />
        <h2 class="subtitle3">
          Soilmates is mijn ontdekkingstocht naar een leven in nauwe verbondenheid met de natuur en
          anderen: zelfvoorzienend leven, autonoom en vrij zijn, oncomfortabel en onbekend. Ik loop
          hierbij graag samen met anderen op en laat mij verrassen door hun vragen en ervaringen.
          <br /><br />
          <ul class="soilmates-ul" style="margin-top: 20px;">
            <li>Wil jij een keer wildkamperen?</li>
            <li>Heb je een stuk grond gekocht en wil je dit natuurvriendelijk aanleggen?</li>
            <li>Heb je hulp nodig bij de bouw van jouw huis van aarde?</li>
            <li>
              Heb je een huis met grote tuin in Zweden – of een ander land - en zoek je iemand die
              jouw tuin onderhoudt als jij een paar weken weg bent?
            </li>
          </ul>
        </h2>
      </div>

      <!-- <div class="klantervaringen-blue">
        <h2 class="subtitle3">
          Ik help je graag verder!
        </h2>
        <h2 class="subtitle3">
          Ik ben een goede organisator die weet van aanpakken.<br />Ik ben resultaatgericht,
          opgewekt en positief, heb lef en ben altijd in voor een avontuur.
        </h2>
      </div> -->

      <div class="worm-container-between first">
        <img src="./assets/SVG/worm3.svg" class="worm worm-3" />
        <img src="./assets/SVG/worm5.svg" class="worm worm-5" />
        <img src="./assets/SVG/worm9.svg" class="worm worm-9" />
        <img src="./assets/SVG/worm13.svg" class="worm worm-13" />
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-vertical is-4 is-about-category">
          <div class="box-img2" style="border-right: 0px solid white;">
            <a href="https://www.soilhoveniers.nl">
              <img class="about-pics" src="./assets/soilmates23/bouwen met hout.jpg"
            /></a>
          </div>
        </div>
        <div class="tile is-vertical is-4 is-about-category">
          <div class="box-img2">
            <img class="about-pics" src="./assets/soilmates23/IMG_2477.jpg" />
          </div>
        </div>
        <div class="tile is-vertical is-4 is-about-category">
          <div class="box-img2">
            <img class="about-pics" src="./assets/soilmates23/primitief vuur maken.jpg" />
          </div>
        </div>
      </div>

      <div class="klantervaringen-img">
        <img src="./assets/soilmates23/Samenwerken 2.jpg" class="about-pics" />
      </div>

      <!-- <div class="klantervaringen-white">
        <h2 class="title3">
          Buitendag mannengroep Reality
        </h2>
        <h2 class="subtitle3">
          Deze middag stond in het teken van ontmoeten, natuur beleven, samenwerken en elkaar
          inspireren. Eten en drinken werd verzorgd door de buitenkok.
        </h2>
      </div> -->

      <!-- <div class="klantervaringen-blue">
        <h2 class="subtitle3">
          “Was een geslaagd event Arjen! Top geregeld! Supertijd gehad!”
        </h2>
        <h3 class="author3">Klaas-Jan Boer, Reality</h3>
      </div> -->

      <div class="worm-container-between second">
        <img src="./assets/SVG/worm18.svg" class="worm worm-18" />
        <img src="./assets/SVG/worm5.svg" class="worm worm-5" />
        <img src="./assets/SVG/worm16.svg" class="worm worm-16" />
        <img src="./assets/SVG/worm9.svg" class="worm worm-9" />
        <img src="./assets/SVG/worm12.svg" class="worm worm-12" />
        <img src="./assets/SVG/worm15.svg" class="worm worm-15" />
      </div>

      <!-- <div class="klantervaringen-img">
        <img src="./assets/Foto_klantervaring2_klein.jpg" class="about-pics" />
      </div> -->

      <!-- <div class="klantervaringen-white">
        <h2 class="title3">
          Feestje Milou
        </h2>
        <h2 class="subtitle3">
          Kinderfeestje in de Haagse duinen voor een groep jongens en meiden uit groep 7.
        </h2>
      </div> -->

      <!-- <div class="klantervaringen-blue">
        <h2 class="subtitle3">
          “Heel gaaf gedaan Arjen. Je hebt ze uitgedaagd en heerlijk bezig gehouden op vele
          aspecten. Gaaf om ze te laten navigeren, tekstueel, wiskundig, biologisch, fysiek en
          tactisch. Teambuilding onder de 16 kids ging heel goed en met een hoop plezier! Van harte
          aanbevolen. Ga zo door!”
        </h2>
        <h3 class="author3">Daan Bergwerff, vader</h3>
      </div> -->

      <div class="worm-container-between third" v-if="pageType == 0">
        <!-- <img src="./assets/SVG/worm19.svg" class="worm worm-19" /> -->
        <img src="./assets/SVG/worm7.svg" class="worm worm-7" />
        <img src="./assets/SVG/worm6.svg" class="worm worm-6" />
        <img src="./assets/SVG/worm12.svg" class="worm worm-12" />
      </div>

      <!-- <div class="klantervaringen-img">
        <img src="./assets/Foto_klantervaring3_klein.jpg" class="about-pics" />
      </div> -->
    </section>
    <!-- Vision -->
    <!-- <div class="klantervaringen-blue">
      <h2 class="subtitle3">

      </h2>
      <h2 class="subtitle3">

      </h2>
    </div> -->
    <section class="section-why" id="contact" v-if="pageType == 0">
      <h1 class="title2">Ik help je graag verder!</h1>
      <h2 class="subtitle3 text-why">
        Ik ben een goede organisator die weet van aanpakken.<br />Ik ben resultaatgericht, opgewekt
        en positief, heb lef en ben altijd in voor een avontuur.
      </h2>
    </section>

    <!-- Contact Info -->
    <section class="section-contact" v-if="pageType == 0">
      <div alt="Soilmates Logo" class="navbar-logo3" />
      <div class="contact-details">
        <span>Arjen Kooiman</span><span>+31 6 2892 1152 </span><span>arjen@soilmates.nl</span>
      </div>
    </section>

    <div class="worm-container-between fourth" v-if="pageType == 0">
      <img src="./assets/SVG/worm17.svg" class="worm worm-17" />
      <img src="./assets/SVG/worm16.svg" class="worm worm-16" />
      <img src="./assets/SVG/worm12.svg" class="worm worm-12" />
      <img src="./assets/SVG/worm10.svg" class="worm worm-10" />
      <img src="./assets/SVG/worm11.svg" class="worm worm-11" />
      <img src="./assets/SVG/worm4.svg" class="worm worm-4" />
      <img src="./assets/SVG/worm1.svg" class="worm worm-1" />
    </div>

    <!-- Footer -->
    <footer class="section-footer" v-if="pageType == 0">
      <div class="columns">
        <div class="column">
          <img class="footer-img" src="./assets/kvk.svg" />
          <strong>KvK: <span>76355802</span></strong>
        </div>
        <div class="column">
          <img class="footer-img" src="./assets/iban.svg" />
          <strong>IBAN: <span>NL 66 KNAB 0259 8859 67</span></strong>
        </div>
        <div class="column">
          <img class="footer-img" src="./assets/btw.svg" />
          <strong>BTW: <span>NL003078679B12</span></strong>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    meta: [
      {
        name: 'description',
        content:
          window.location.host === 'localhost:8082' || window.location.host === 'www.soilmates.nl'
            ? 'Connecting people in Nature | Natuur en Mensen | Arjen Kooiman | +31 6 2892 1152 '
            : 'Natuur bij jou aan huis | Tuinontwerp, tuinaanleg en tuinonderhoud | Arjen Kooiman | +31 6 2892 1152',
      },
    ],
  },
  components: {},
  data() {
    return {
      isImageModalActive: false,
      idBlocks: ['home', 'categories', 'toolbox', 'meetthemate', 'klantervaringen', 'contact'],
      idBlocks2: ['home', 'categories', 'werkwijze', 'meetthemate', 'klantervaringen', 'contact'],
      activeBlock: 1,
      activeMenu: false,
      blockEls: [],
      blockBreakpoints: [],
      pageType: 0,
      colors: {
        soilmates: '#242b37',
        soilhoveniers: '#F8F3C0',
        soilhoveniersbg2: '#AB9467',
        soilmatesOverlay: 'rgba(36, 43, 55, 0.9)',
        soilhoverniersOverlay: 'rgba(171, 148, 103, 0.9)',
        soilmatesOverlay2: 'rgba(36, 43, 55, 1.0)',
        soilhoverniersOverlay2: 'rgba(171, 148, 103, 1.0)',
        soilhoveniersbg1: '#D0B686',
        soilhoveniersbg3: '#CFB585',
      },
    };
  },
  async beforeMount() {
    const url = window.location.host;
    if (url.split(':')[0] === 'localhost') {
      document.title = 'Soilhoveniers';
      // for testing locally, defaults to soilhoveniers.nl
      this.pageType = 0;
    } else if (url === 'soilhoveniers.nl' || url === 'www.soilhoveniers.nl') {
      document.title = 'Soilhoveniers';
      const favicon = document.getElementById('favicon');
      favicon.href = 'zon.svg';
      // soilhoveniers.nl
      this.pageType = 2;
    } else {
      document.title = 'Soilmates';
      // soilmates.nl
      this.pageType = 0;
    }
  },
  mounted() {
    this.calculateAdjustedBlockPositions();

    // window.setTimeout(() => {
    //   this.isImageModalActive = true;
    // }, 1000);

    document.querySelector('body').style.backgroundColor = this.pageType === 0 ? this.colors.soilmates : this.colors.soilhoveniers;
    document.querySelector('html').style.backgroundColor = this.pageType === 0 ? this.colors.soilmates : this.colors.soilhoveniers;
  },
  computed: {
    cssProps() {
      return {
        '--bg-color': this.pageType === 0 ? this.colors.soilmates : this.colors.soilhoveniers,
        '--bg-color2': this.pageType === 0 ? this.colors.soilmates : this.colors.soilhoveniersbg1,
        '--bg-color-overlay':
          this.pageType === 0 ? this.colors.soilmatesOverlay : this.colors.soilhoverniersOverlay,
        '--bg-color-overlay2':
          this.pageType === 0 ? this.colors.soilmatesOverlay2 : this.colors.soilhoverniersOverlay2,
        '--bg1': this.colors.soilhoveniersbg1,
        '--bg2': this.pageType === 0 ? 'transparent' : this.colors.soilhoveniersbg2,
        '--bg3': this.pageType === 0 ? 'white' : this.colors.soilhoveniersbg3,
      };
    },
  },
  watch: {
    pageType(val) {
      document.querySelector('body').style.backgroundColor = val === 0 ? this.colors.soilmates : this.colors.soilhoveniers;
      document.querySelector('html').style.backgroundColor = val === 0 ? this.colors.soilmates : this.colors.soilhoveniers;
      this.$nextTick(() => {
        this.calculateAdjustedBlockPositions();
      });
    },
  },
  methods: {
    openFlyerLink() {
      window.location.href = '/Flyer-Soilmates-buitenweekend.pdf';
    },
    changeType() {
      this.pageType = this.pageType === 2 ? 0 : 2;
      // window.location.hostname = this.pageType === 2 ? 'soilhoveniers.nl' : 'soilmates.nl';
    },
    calculateAdjustedBlockPositions() {
      if (this.pageType === 0) {
        this.blockEls = this.idBlocks.map((el) => document.getElementById(el));
      } else {
        this.blockEls = this.idBlocks2.map((el) => document.getElementById(el));
      }

      // calculate block breakpoints for each block
      const { scrollTop } = document.documentElement;
      this.blockBreakpoints = this.blockEls.map((el) => {
        const { top } = el.getBoundingClientRect();
        const oldOffset = top + scrollTop - 100;
        return oldOffset;
      });
      this.initObserver();
    },
    initObserver() {
      const options = {
        // create an array of thresholds from .00 to .99
        threshold: [...Array(10)].map((el, index) => 0.1 * index),
      };

      const handleIntersectionEvent = () => {
        // treat this much like an onscroll handler
        const { scrollTop } = document.documentElement;
        this.blockBreakpoints.map((bp, index) => {
          if (scrollTop > 400) {
            // show menu
            this.activeMenu = true;
          } else {
            this.activeMenu = false;
          }
          // is scrollTop in the region between bp and bp + 1?
          if (
            Math.abs(scrollTop - bp)
            < this.blockBreakpoints[index] - this.blockBreakpoints[index - 1] + 100
          ) {
            this.activeBlock = index;
          }
          if (
            scrollTop
            > this.blockBreakpoints[this.blockBreakpoints.length - 1] - window.innerHeight
          ) {
            this.activeBlock = this.blockBreakpoints.length;
          }
          return false;
        });
      };

      const observer = new IntersectionObserver(handleIntersectionEvent, options);

      this.blockEls.forEach((el) => observer.observe(el));
    },
  },
};
</script>

<style>
.title-corr3 {
  padding-top: 150px !important;
}
.soilmates-cat {
  text-align: center !important;
}
.button.is-dark {
  background-color: #242a36 !important;
}
.button.is-dark strong {
  color: whitesmoke !important;
}
.flyer-button {
  width: 100%;
}
.flyer {
  cursor: pointer;
  /* width: 100vw !important; */
}
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;700;900&display=swap');
.soilhoveniers-ul {
  display: block;
}
.soilhoveniers-ul li {
  list-style-type: none;
  position: relative;
  margin-top: 7px;
}

.soilhoveniers-ul li:before {
  top: 0.37em;
  content: ' ';
  -webkit-mask: url(./assets/kvk.svg) no-repeat center center;
  mask: url(./assets//kvk.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
  background: #706352;
  width: 0.7em;
  height: 0.7em;
  position: absolute;
  border-radius: 50%;
  left: -1em;
}

.soilhoveniers-ul li:nth-child(3n + 2):before {
  background: #706352;
  -webkit-mask: url(./assets/btw.svg) no-repeat center center;
  mask: url(./assets//btw.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
}

.soilhoveniers-ul li:nth-child(3n + 3):before {
  background: #706352;
  -webkit-mask: url(./assets/iban.svg) no-repeat center center;
  mask: url(./assets//iban.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
}

/* begin */

.soilmates-ul {
  display: block;
}
.soilmates-ul li {
  list-style-type: none;
  position: relative;
  margin-top: 7px;
}

.soilmates-ul li:before {
  top: 0.37em;
  content: ' ';
  -webkit-mask: url(./assets/kvk.svg) no-repeat center center;
  mask: url(./assets//kvk.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
  background: var(--bg-color);
  width: 0.7em;
  height: 0.7em;
  position: absolute;
  border-radius: 50%;
  left: -1em;
}

.soilmates-ul li:nth-child(3n + 2):before {
  background: var(--bg-color);
  -webkit-mask: url(./assets/btw.svg) no-repeat center center;
  mask: url(./assets//btw.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
}

.soilmates-ul li:nth-child(3n + 3):before {
  background: var(--bg-color);
  -webkit-mask: url(./assets/iban.svg) no-repeat center center;
  mask: url(./assets//iban.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
}

/* end */

.soilmates-ul2 {
  display: block;
}
.soilmates-ul2 li {
  list-style-type: none;
  position: relative;
  margin-top: 7px;
}

.soilmates-ul2 li:before {
  top: 0.37em;
  content: ' ';
  -webkit-mask: url(./assets/kvk.svg) no-repeat center center;
  mask: url(./assets//kvk.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
  background: whitesmoke;
  width: 0.7em;
  height: 0.7em;
  position: absolute;
  border-radius: 50%;
  left: -1em;
}

.soilmates-ul2 li:nth-child(3n + 2):before {
  background: whitesmoke;
  -webkit-mask: url(./assets/btw.svg) no-repeat center center;
  mask: url(./assets//btw.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
}

.soilmates-ul2 li:nth-child(3n + 3):before {
  background: whitesmoke;
  -webkit-mask: url(./assets/iban.svg) no-repeat center center;
  mask: url(./assets//iban.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
}

.elevated-z {
  z-index: 101 !important;
}

.sun-small {
  width: 90px;
  display: block;
  margin: 20px;
  /* margin: 0 60% 0px 35% !important; */
  margin: 0 auto;
}

.boom-small {
  width: 70px;
  height: 70px;
  display: block;
  /* margin: 20px; */
  /* margin: 0 60% 0px 35% !important; */
  margin: 0 auto;
  background-color: #222b19;
  -webkit-mask: url(./assets/boomdicht.svg) no-repeat center center;
  mask: url(./assets/boomdicht.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
}

.boom-big {
  z-index: 1;
  width: 700px;
  height: 900px;
  display: block;
  margin: 20px;
  margin: 0;
  background: url(./assets/boom2.png) no-repeat center center;
  background-size: 65%;
  position: absolute;
  top: 250px;
  left: -310px;
}

.boom-sun-big {
  width: 150px;
  height: 800px;
  display: block;
  margin: 0;
  background: url(./assets/boom2.png) no-repeat right center;
  background-size: 310%;
  /* background-color: yellow; */
  /* -webkit-mask: url(./assets/boommetzon.svg) no-repeat center center; */
  /* mask: url(./assets/boommetzon.svg) no-repeat center center; */
  /* -webkit-mask-size: 100% 100%; */
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  position: absolute;
  top: 0px;
  right: 0px;
}

.sun-big {
  width: 200px;
  height: 200px;
  display: block;
  margin: 20px;
  margin: 0;
  background: url(./assets/zon.svg) no-repeat center center;
  position: absolute;
  top: -100px;
  right: -80px;
}

.sun-big2 {
  width: 280px;
  height: 280px;
  display: block;
  margin: 20px;
  margin: 0;
  background: url(./assets/zon.svg) no-repeat center center;
  position: absolute;
  top: 1400px;
  right: -80px;
}

.soilmates-navbar {
  transition: 0.2s all ease-in-out !important;
  opacity: 0.9;
  -webkit-backdrop-filter: blur(2px) !important;
}

.soilmates-navbarb {
  transition: 0.2s all ease-in-out !important;
  height: 75px;
  font-size: 0.9em;
}
.soilmates-navbarb .navbar-item {
  /* background-color: rgba(0, 255, 0, 0.5); */
  flex-basis: unset !important;
}
.soilmates-navbarb .navbar-item:nth-child(1) {
  width: 50px !important;
}
.soilmates-navbarb .navbar-item:nth-child(2) {
  width: 50px !important;
}
.soilmates-navbarb .navbar-item:nth-child(3) {
  width: 100px !important;
}
.soilmates-navbarb .navbar-item:nth-child(4) {
  width: 100px !important;
}
.soilmates-navbarb .navbar-item:nth-child(5) {
  width: 50px !important;
}
.friemels {
  background: url('./assets/friemels.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.friemels2 {
  background: url('./assets/friemels.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.logo-box {
  width: 250px;
  height: 200px;
  background-color: transparent;
  display: block;
  position: relative;
  margin: 0 auto;
  text-align: center;
  top: 240px;
  z-index: 99;
}
.background-box {
  background-color: var(--bg2) !important;
  width: 100%;
  height: 50px;
}
.logo-box div:nth-child(1) {
  float: left;
  display: inline-block;
  margin-top: 30px !important;
  /* margin-left: 10px; */
}
.logo-box div:nth-child(2) {
  float: right;
  display: inline-block;
}
.worm-container {
  max-width: 1100px;
  min-width: 1118px;
  height: 100px;
  position: relative;
  display: block;
  margin: 0 auto;
  top: -120vh;
  left: -80px;
  transition: 1s all ease-in-out;
}

.worm-container-between {
  position: relative;
  display: block;
  max-width: 1200px;
  min-width: 1198px;
  margin: 0 auto;
}

.worm-container-between2 {
  position: relative;
  display: block;
  max-width: 1200px;
  min-width: 1198px;
  margin: 0 auto;
}

.worm {
  display: block;
  position: absolute;
  background-color: transparent;
  transition: 0.3s all ease-in-out;
  opacity: 0.7;
}

.worm:hover,
.fourth .worm:hover,
.third .worm:hover,
.second .worm:hover,
.first .worm:hover {
  transform: rotate(5deg);
  transition: 0.1s all ease-in-out;
  /* opacity: 0.8; */
}

.worm-19 {
  width: 350px;
  transform: rotate(0deg);
  top: -50px;
  left: -50px;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-18 {
  width: 50px;
  display: block;
  transform: rotate(0deg);
  top: 180px;
  left: 60px;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-17 {
  width: 50px;
  display: block;
  transform: rotate(0deg);
  top: 350px;
  left: -50px;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-16 {
  width: 50px;
  display: block;
  transform: rotate(0deg);
  top: 580px;
  left: 0px;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-15 {
  width: 70px;
  display: block;
  transform: rotate(0deg);
  top: 780px;
  left: -15px;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-14 {
  width: 45px;
  display: block;
  transform: rotate(0deg);
  top: 960px;
  left: -15px;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-13 {
  width: 200px;
  display: block;
  transform: rotate(0deg);
  top: 980px;
  left: 80px;
  z-index: 10;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-12 {
  width: 180px;
  display: block;
  transform: rotate(0deg);
  top: 510px;
  left: 150px;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-11 {
  width: 130px;
  display: block;
  transform: rotate(0deg);
  top: 490px;
  left: 270px;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-10 {
  width: 25px;
  display: block;
  transform: rotate(0deg);
  top: 600px;
  left: 450px;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-9 {
  width: 105px;
  display: block;
  transform: rotate(0deg);
  top: 1029px;
  left: 385px;
  z-index: 10;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-8 {
  width: 300px;
  display: block;
  transform: rotate(0deg);
  top: 500px;
  left: 585px;
  z-index: 10;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-7 {
  width: 250px;
  display: block;
  transform: rotate(0deg);
  top: 380px;
  left: 690px;
  z-index: 10;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-6 {
  width: 45px;
  display: block;
  transform: rotate(0deg);
  top: 990px;
  left: 600px;
  z-index: 10;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-5 {
  width: 35px;
  display: block;
  transform: rotate(0deg);
  top: 280px;
  left: 1010px;
  z-index: 10;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-4 {
  width: 35px;
  display: block;
  transform: rotate(0deg);
  top: 685px;
  left: 1010px;
  z-index: 10;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-3 {
  width: 350px;
  display: block;
  transform: rotate(0deg);
  top: 885px;
  left: 850px;
  z-index: 10;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-2 {
  width: 90px;
  display: block;
  transform: rotate(0deg);
  top: 985px;
  left: 1200px;
  z-index: 10;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.worm-1 {
  width: 60px;
  display: block;
  transform: rotate(0deg);
  top: 685px;
  left: 1230px;
  z-index: 10;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

html.has-navbar-fixed-top,
body.has-navbar-fixed-top {
  padding-top: 0 !important;
}

.klantervaringen-brown {
  background-color: #d0b686;
  margin-top: 90px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 65px;
  padding-right: 65px;
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  text-align: left !important;
  color: #323337;
}

.klantervaringen-light {
  background-color: var(--bg-color);
  margin-top: 0px;
  padding-top: 40px;
  padding-bottom: 50px;
  padding-left: 65px;
  padding-right: 65px;
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  text-align: left !important;
  color: #706352;
}

.klantervaringen-lightish {
  background-color: #d0b686;
  margin-top: 0px;
  padding-top: 80px;
  padding-bottom: 100px;
  padding-left: 65px;
  padding-right: 65px;
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  text-align: left !important;
  color: #706352;
}

.klantervaringen-lightish2 {
  background-color: rgba(208, 182, 134, 0.76) !important;
  margin-top: 0px;
  padding-top: 80px;
  padding-bottom: 100px;
  padding-left: 65px;
  padding-right: 65px;
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  text-align: left !important;
  color: #706352;
  font-size: 1.2rem;
}

.klantervaringen-lightish3 {
  background-color: #ac9568;
  margin-top: 0px;
  padding-top: 80px;
  padding-bottom: 100px;
  padding-left: 65px;
  padding-right: 65px;
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  text-align: left !important;
  color: white;
  font-size: 1.2rem;
}

.klantervaringen-lightish4 {
  background-color: #f8f3c0;
  margin-top: 0px;
  padding-top: 80px;
  padding-bottom: 100px;
  padding-left: 65px;
  padding-right: 65px;
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  text-align: left !important;
  color: #706352;
  font-size: 1.2rem;
}

.klantervaringen-lightish4b {
  background-color: #f8f3c0;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 80px;
  padding-left: 0;
  padding-right: 0;
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  text-align: left !important;
  color: #706352;
  font-size: 1.2rem;
}

.klantervaringen-lightish5 {
  background-color: #f8f3c0;
  margin-top: 0px;
  padding-top: 0;
  padding-bottom: 100px;
  padding-left: 65px;
  padding-right: 65px;
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  text-align: left !important;
  color: #706352;
  font-size: 1.2rem;
}

.klantervaringen-light .subtitle3b {
  text-align: center;
  font-size: 1.1em !important;
}

.subtitle4b {
  margin-top: 0px !important;
  font-size: 1.4rem !important;
  font-weight: 800 !important;
  font-family: 'Public Sans', sans-serif !important;
  letter-spacing: 1px;
}

.subtitle4bh1 {
  margin-top: 0px !important;
  font-size: 2rem !important;
  font-weight: 800 !important;
  font-family: 'Public Sans', sans-serif !important;
  letter-spacing: 2px;
}

.klantervaringen-white {
  background-color: white;
  margin-top: 0px;
  padding-top: 80px;
  padding-bottom: 100px;
  padding-left: 65px;
  padding-right: 65px;
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  text-align: left !important;
  color: var(--bg-color);
}

.klantervaringen-blue {
  background-color: var(--bg-color);
  margin-top: 0px;
  padding-top: 80px;
  padding-bottom: 100px;
  padding-left: 65px;
  padding-right: 65px;
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  text-align: left !important;
  color: whitesmoke;
}

.klantervaringen-blue2 {
  background-color: var(--bg-color);
  margin-top: 0px;
  padding-top: 80px;
  padding-bottom: 0px;
  padding-left: 65px;
  padding-right: 65px;
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  text-align: left !important;
  color: whitesmoke;
}

.klantervaringen-white2 {
  background-color: white;
  margin-top: 0px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  text-align: left !important;
  color: var(--bg-color);
}

.klantervaringen-white2 .tile.is-parent {
  padding: 0;
  margin: 0;
}

.klantervaringen-white2 .box-arjen-staand {
  padding-right: 0;
  padding-bottom: 0px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  overflow: hidden !important;
  border: 0px solid white;
}

.box-arjen-staand2 {
  padding-right: 0;
  padding-bottom: 0px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  overflow: hidden !important;
  border: 0px solid white;
}

.box-arjen-staand3 {
  padding-right: 0;
  padding-bottom: 0px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
  border: 0px solid white;
}

.klantervaringen-nice {
  background-color: #ac9568;
  margin-top: 0px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 65px;
  padding-right: 65px;
  font-family: 'Public Sans', sans-serif;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: 2px;
  text-align: center !important;
  color: white;
  font-size: 2em !important;
}

.klantervaringen-imgb {
  background-color: white;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  /* position: relative; */
  /* margin-top: 150px; */
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  text-align: left !important;
  color: white;
  overflow: hidden !important;
}

.klantervaringen-img {
  background-color: white;
  margin-top: -12px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  text-align: left !important;
  color: white;
  height: 630px;
  overflow: hidden !important;
}

.klantervaringen-blue .subtitle3 {
  margin-bottom: 0;
  max-width: 100%;
  text-align: center;
}

.klantervaringen-blue .subtitle3:not(:first-child) {
  margin-top: 50px !important;
  max-width: 98%;
  text-align: center;
}

.klantervaringen-blue .subtitle3:last-child {
  margin-bottom: 50px !important;
}

.klantervaringen-blue .author3 {
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  font-style: italic;
  font-family: 'Public Sans', sans-serif !important;
  letter-spacing: 1px;
  margin: 5px auto;
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 50px;
}

.title3 {
  margin-top: 0px !important;
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  font-family: 'Public Sans', sans-serif !important;
  letter-spacing: 1px;
}

.subtitle3 {
  margin-top: 0px !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  font-family: 'Public Sans', sans-serif !important;
  letter-spacing: 1px;
}

.subtitle4 {
  margin-top: 0px !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  font-family: 'Public Sans', sans-serif !important;
  letter-spacing: 1px;
  color: var(--bg-color);
}

.subtitle3b {
  margin-top: 0px !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  font-family: 'Public Sans', sans-serif !important;
  letter-spacing: 1px;
}

.subtitle3b2 {
  margin-top: 20px !important;
  font-size: 1.35rem !important;
  font-weight: 800 !important;
  font-family: 'Public Sans', sans-serif !important;
  letter-spacing: 1px;
}

.toolbox-title {
  position: relative;
  display: block;
  /* top: 100px; */
  height: 100px !important;
}

.toolbox-subtitle {
  position: relative;
  display: block;
  top: -50px;
  height: 50px !important;
  /* background-color: red; */
}

.tile {
  transition: 0.1s all ease-in-out !important;
}

.tile .soilimg img {
  width: 100% !important;
}

.tile .soiltag {
  background-color: #706352;
  color: white;
  height: 50px;
  line-height: 50px;
  font-size: 1.2em;
  margin-top: auto;
  margin-left: -100%;
  left: 0;
  width: 100%;
  display: inline-block;
  position: relative;
  text-align: center;
  bottom: 0px;
}

.hero.is-fullheight {
  min-height: 150vh !important;
}

.hero2.is-fullheight {
  height: 80vh !important;
  margin-top: -100px;
}

.is-about-category {
  /* padding-right: 4px; */
  transition: 0.1s all ease-in-out !important;
}

.is-about-category:hover {
  transition: 0.1s all ease-in-out !important;
}

.is-about-category:hover > .box-cat-title {
  background-color: #4d5820;
  transition: 0.1s all ease-in-out !important;
}

.is-about-category:hover > .box-cat-content {
  color: white !important;
  background-color: var(--bg-color);
  transition: 0.1 all ease-in-out !important;
}

.box-cat-content {
  transition: 0.1 all ease-in-out !important;
  color: var(--bg-color) !important;
  background-color: white;
  padding: 0px !important;
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  font-size: 1.3rem;
  text-align: left !important;
  padding-left: 15px !important;
  padding-top: 10px !important;
  padding-right: 10px !important;
  height: 550px;
  letter-spacing: 1px;
}

.meetthemate-text {
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  font-size: 1.7rem;
  text-align: left !important;
  padding-left: 50px !important;
  padding-right: 15px !important;
  padding-top: 10px !important;
}

.categories-text {
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: left !important;
  padding-left: 50px !important;
  padding-right: 15px !important;
  padding-top: 10px !important;
}

.categories-text3 {
  font-family: 'Public Sans', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center !important;
  /* padding-left: 50px !important; */
  padding-right: 15px !important;
  padding-top: 10px !important;
  color: var(--bg-color);
}

.categories-fix {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.categories-fix2 {
  /* padding-left: 5vw !important; */
  /* padding-right: 5vw !important; */
  z-index: 2;
}

.categories-fix3 {
  margin-top: 30px;
}

.meetthemate-columns {
  position: relative;
  top: 50px !important;
  margin-bottom: 100px !important;
  padding-top: 10px !important;
}

.categories-columns {
  position: relative;
  top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 10px !important;
  max-width: 700px !important;
  /* background-color: red; */
  margin: 0 auto !important;
  /* padding-left: 100px !important; */
  /* padding-right: 100px !important; */
}
.categories-columns .categories-text {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.wat-columns {
  position: relative;
  top: 25px !important;
  margin-bottom: 25px !important;
  padding-top: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

#categories > div > div:nth-child(1) > div.box-cat-content {
  margin-left: 8px;
}

#categories > div > div:nth-child(3) > div.box-cat-content {
  margin-right: 8px;
}

.box-cat-title {
  background-color: var(--bg-color);
  padding: 25px;
  transition: 0.1 all ease-in-out !important;
}
.box-cat-title .title {
  padding: 0 !important;
  margin: 0 !important;
}

.box-img {
  padding-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  overflow: hidden !important;
  border: 4px solid white;
  border-right: 0px;
  border-left: 0px;
}

#categories > div > div:nth-child(1) > div.box-img {
  border-right: 4px solid white !important;
}

#categories > div > div:nth-child(2) > div.box-img {
  border-right: 0px !important;
  border-left: 0px !important;
}

#categories > div > div:nth-child(3) > div.box-img {
  border-left: 4px solid white !important;
}

.box-img2,
.box-img2 a {
  padding-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  overflow: hidden !important;
  border: 4px solid white;
  /* border-right: 0px; */
  border-left: 0px;
}

#categories > div > div:nth-child(1) > div.box-img2 {
  border-right: 4px solid white !important;
}

#categories > div > div:nth-child(2) > div.box-img2 {
  border-right: 0px !important;
  border-left: 0px !important;
}

#categories > div > div:nth-child(3) > div.box-img2 {
  border-left: 4px solid white !important;
}

.mobile {
  display: none !important;
}
.not-mobile {
  display: block !important;
}

@media (max-width: 1118px) {
  .worm-container,
  .first,
  .second,
  .third,
  .fourth {
    display: none;
    transition: 1s all ease-in-out;
  }
  .toolbox-subtitle {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 768px) {
  .mobile {
    display: block !important;
  }
  .not-mobile {
    display: none !important;
  }
  .wat-columns {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .categories-fix3 {
    margin-top: 0;
  }
  .soilmates-navbarb .navbar-item:nth-child(1):not(.navbar-brand a.navbar-item:nth-child(1)),
  .soilmates-navbarb .navbar-item:nth-child(2),
  .soilmates-navbarb .navbar-item:nth-child(3),
  .soilmates-navbarb .navbar-item:nth-child(4),
  .soilmates-navbarb .navbar-item:nth-child(5) {
    width: 100% !important;
  }
  .categories-fix2 .categories-text {
    text-align: center !important;
  }
  .categories-columns {
    padding-left: 20px !important;
    padding-right: 20px !important;
    z-index: 2 !important;
  }
  #categories > div > div:nth-child(1) > div.box-img {
    border: 0 solid white !important;
  }

  #categories > div > div:nth-child(2) > div.box-img {
    border: 0 solid white;
  }

  #categories > div > div:nth-child(3) > div.box-img {
    border: 0 solid white !important;
  }
  .box-img {
    padding-bottom: 0;
  }
  .navbar-menu {
    background-color: var(--bg-color) !important;
    /* background-color: rgba(36, 43, 55,0.8) !important; */
    /* backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px); */
    color: whitesmoke !important;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .box-img2,
  .box-img2 a {
    padding-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    overflow: hidden !important;
    border: 4px solid white;
    border-right: 0px;
    border-left: 0px;
    border: 0px;
    margin-bottom: 4px;
  }

  .klantervaringen-img {
    margin-top: 4px;
  }

  .friemels {
    background-size: 150vh !important;
  }
  .box-cat-content {
    margin: 0 10px;
    padding: 20px !important;
    height: inherit;
  }
  .tile.is-ancestor {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: -0.75rem;
  }

  .columns {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: -0.75rem;
  }
  .meetthemate-column {
    padding: 0;
    width: 100%;
  }
}

.about-pics {
  flex-shrink: 0;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.move-img-up {
  margin-top: -180px;
}

.move-img-up2 {
  margin-top: -310px;
}

.move-img-up3 {
  margin-top: 0;
}

.meetthemate-column {
  padding: 0.3rem !important;
  width: 100%;
}

.meetthemate-column-right {
  margin-right: 50px !important;
  width: 100%;
}

.meetthemate-column-bottom {
  margin-right: 55px !important;
  /* margin-left: 5px; */
  padding-top: 5px;
  padding-bottom: 10px;
}

.box-arjen-staand {
  padding-right: 0;
  padding-bottom: 0px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  overflow: hidden !important;
  border: 3px solid white;
}

.box-arjen-liggend {
  padding-right: 0;
  padding-bottom: 4px;
  padding-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 420px;
  overflow: hidden !important;
  border: 3px solid white;
}

.arjen-links {
  flex-shrink: 0;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.arjen-links2 {
  flex-shrink: 0;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.arjen-links3 {
  flex-shrink: 0;
  min-height: 96%;
  min-width: 100%;
  object-fit: cover;
}

.arjen-rechts {
  flex-shrink: 0;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.arjen-onder {
  flex-shrink: 0;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

@media (max-width: 1023px) {
  .boom-sun-big,
  .sun-big,
  .boom-big,
  .sun-big2 {
    display: none;
  }
  html,
  body {
    max-width: 100%;
    overflow-x: hidden;
  }
  .klantervaringen-imgb {
    height: 60vh !important;
  }
  .categories-text {
    padding: 0 !important;
  }
  .categories-fix2 .categories-text {
    text-align: center !important;
    padding: 20px !important;
    /* margin: 20px !important; */
  }
  .klantervaringen-light,
  .klantervaringen-lightish4,
  .klantervaringen-lightish2,
  .klantervaringen-brown,
  .klantervaringen-lightish5,
  .klantervaringen-lightish3,
  .klantervaringen-nice {
    width: 100% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .contact-detailsbox {
    top: 100px;
  }
  .section-footerb {
    padding-top: 90px !important;
  }
  .soilhoveniers-ul {
    margin-left: 20px;
  }
  .subtitle4bh1 {
    padding-top: 20px;
  }
  .box-arjen-staand {
    max-height: 300px !important;
  }
  .soilmates-navbarb .navbar-start a {
    color: white !important;
  }
  .navbar-menu {
    background-color: #d0b686 !important;
    -webkit-box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
    box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
    /* padding: 0 !important; */
    margin-top: -12px !important;
  }
  a.navbar-item:hover:not(.navbar-brand a.navbar-item:nth-child(1)) {
    background-color: #9e8a65 !important;
  }
  .navbar-logob,
  .navbar-burger {
    margin-top: -10px !important;
  }
  .navbar-logob {
    left: 25px !important;
  }
  .box-arjen-staand {
    padding-right: 0;
    padding-bottom: 0px;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
  }
  .meetthemate-column-bottom {
    margin-right: 0 !important;
  }
  .meetthemate-text {
    padding-right: 30px !important;
    font-size: 1.2rem;
  }
}

.footer-img {
  width: 12px;
  position: relative;
  top: 3px;
  left: -5px;
}
.footer-img2 {
  -webkit-mask: url(./assets/kvk.svg) no-repeat center center;
  mask: url(./assets/kvk.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
  background-color: #f8f3c0 !important;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 3px;
  left: -5px;
}
.footer-img3 {
  -webkit-mask: url(./assets/btw.svg) no-repeat center center;
  mask: url(./assets/btw.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
  background-color: #f8f3c0 !important;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 3px;
  left: -5px;
}
.footer-img4 {
  -webkit-mask: url(./assets/iban.svg) no-repeat center center;
  mask: url(./assets/iban.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
  background-color: #f8f3c0 !important;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 3px;
  left: -5px;
}
#home .hero-body {
  /* background: linear-gradient(rgba(36, 43, 55, 0.9), rgba(36, 43, 55, 0.9)), */
  background: linear-gradient(var(--bg-color-overlay), var(--bg-color-overlay)),
    url('./assets/soilmates-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#home .hero-body .container {
  top: -250px !important;
}

#home .hero-body2 {
  /* background: linear-gradient(rgba(36, 43, 55, 0.9), rgba(36, 43, 55, 0.9)), */
  background: linear-gradient(rgba(226, 196, 137, 0.1), rgba(226, 196, 137, 0.1)),
    url('./assets/apples.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

#home .hero-body2 .container {
  top: 300px !important;
}

.soilmates-background {
  background-color: var(--bg-color2);
}

.section-category {
  margin-top: 0px !important;
  position: relative;
  background-color: white;
}

.section-category3 {
  margin-top: 25px !important;
  padding-top: 25px;
  /* margin-bottom: 125px; */
  position: relative;
  background-color: white;
}

.section-category2 {
  margin-top: -100px !important;
  position: relative;
  background-color: var(--bg1) !important;
  /* height: 350px; */
}

.section-werkwijze {
  margin-top: -10px !important;
  position: relative;
  background-color: white !important;
  /* height: 350px; */
}

.section-toolbox {
  margin-top: 100px !important;
  margin-bottom: 50px !important;
}

.section-meet {
  margin-top: 55px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.section-meet2 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  text-align: left;
}
.section-meet {
  margin-top: 55px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.section-base {
  margin-top: 250px !important;
  height: 300px !important;
}

.section-why {
  margin-top: 100px !important;
  margin-bottom: 50px !important;
}

.text-why {
  margin: 50px 14% 95px 14% !important;
  /* text-align: justify; */
}

.section-klantervaringen .title2 {
  margin-bottom: 70px;
}

.first .worm-3 {
  top: -50px !important;
  left: -80px !important;
  -webkit-transform: scaleY(-1) scaleX(-1);
  transform: scaleY(-1) scaleX(-1);
}

.first .worm-5 {
  top: -50px;
  left: 500px;
}

.first .worm-9 {
  top: -100px;
  left: 640px;
  transform: rotate(180deg);
}

.first .worm-13 {
  top: -100px;
  left: 1070px;
  -webkit-transform: scaleY(-1) scaleX(-1);
  transform: scaleY(-1) scaleX(-1);
}

.second {
  left: -70px;
}

.third {
  left: -30px;
}

.fourth {
  top: -700px;
  filter: invert(27%) saturate(100%) brightness(40%) contrast(97%);
}

.fifth {
  /* height: 100vh; */
  position: relative;
  top: -1000px;
  filter: invert(27%) saturate(100%) brightness(40%) contrast(97%);
}

.fourth .worm-17 {
  width: 50px;
  display: block;
  transform: rotate(0deg);
  top: 450px;
  left: -50px;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.fourth .worm-16 {
  width: 50px;
  display: block;
  transform: rotate(0deg);
  top: 610px;
  left: 0px;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.fourth .worm-12 {
  width: 180px;
  display: block;
  transform: rotate(0deg);
  top: 480px;
  left: 85px;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.fourth .worm-10 {
  width: 20px;
  display: block;
  transform: rotate(0deg);
  top: 540px;
  left: 350px;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.fourth .worm-11 {
  width: 130px;
  display: block;
  transform: rotate(-10deg);
  top: 460px;
  left: 970px;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.fourth .worm-4 {
  width: 35px;
  display: block;
  transform: rotate(0deg);
  top: 620px;
  left: 1130px;
  z-index: 10;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.fourth .worm-1 {
  width: 60px;
  display: block;
  transform: rotate(0deg);
  top: 590px;
  left: 1230px;
  z-index: 10;
  /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
}

.second .worm-18 {
  top: -50px;
  left: 75px;
  -webkit-transform: scaleY(-1) rotate(160deg);
  transform: scaleY(-1) rotate(160deg);
}

.second .worm-16 {
  top: -150px;
  left: 175px;
  -webkit-transform: scaleY(-1) rotate(180deg);
  transform: scaleY(-1) rotate(180deg);
}

.second .worm-5 {
  top: 185px;
  left: 260px;
  -webkit-transform: scaleY(-1) rotate(180deg);
  transform: scaleY(-1) rotate(180deg);
}

.second .worm-9 {
  top: -25px;
  left: 800px;
  -webkit-transform: scaleY(-1) rotate(180deg);
  transform: scaleY(-1) rotate(180deg);
}

.second .worm-12 {
  top: -90px;
  left: 1050px;
  -webkit-transform: scaleY(-1) rotate(180deg);
  transform: scaleY(-1) rotate(180deg);
}

.second .worm-15 {
  top: -190px;
  left: 1240px;
  -webkit-transform: scaleY(-1) scaleX(-1) rotate(180deg);
  transform: scaleY(-1) rotate(180deg);
}

.third .worm-19 {
  top: -100px;
  left: -50px;
  -webkit-transform: scaleY(-1) scaleX(-1) rotate(19deg);
  transform: scaleY(-1) scaleX(-1) rotate(190deg);
}

.third .worm-7 {
  top: -50px;
  left: 280px;
  -webkit-transform: scaleY(-1) scaleX(-1) rotate(19deg);
  transform: scaleY(-1) scaleX(-1) rotate(190deg);
}

.third .worm-6 {
  top: -70px;
  left: 1000px;
  -webkit-transform: scaleY(-1) scaleX(-1) rotate(19deg);
  transform: scaleY(-1) scaleX(-1) rotate(190deg);
}

.third .worm-12 {
  top: -70px;
  left: 1100px;
  -webkit-transform: scaleY(-1) rotate(19deg);
  transform: scaleX(-1) scaleY(-1) rotate(100deg);
}

@media (max-width: 1023px) {
  .section-why {
    margin-top: 100px !important;
    margin-bottom: 50px !important;
  }
  .section-why .title2 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.section-contact {
  background-color: var(--bg3) !important;
  height: 270px;
}

.section-contact2 {
  background-color: var(--bg3) !important;
  height: 150px;
}

.section-contactb {
  background-color: var(--bg3) !important;
  height: 500px;
  /* margin-top: 300px; */
}

.section-footer {
  background-color: var(--bg2) !important;
  color: rgba(36, 43, 55, 1) !important;
  height: 80px;
  margin-top: 0 !important;
  padding: 10px;
  font-size: 0.9rem;
  line-height: 60px;
}

.section-footer .column strong {
  /* color: rgba(36, 43, 55, 1) !important; */
  color: whitesmoke !important;
}

.section-footerb {
  background-color: var(--bg2) !important;
  color: whitesmoke !important;
  /* height: 80px; */
  margin-top: 0 !important;
  /* padding: 10px; */
  padding-top: 10px;
  font-size: 0.9rem;
  line-height: 60px;
}

.section-footerb .column strong {
  color: var(--bg-color) !important;
}

.section-intro {
  padding: 30px 10px 30px 10px;
  margin: 0;
  margin-top: -55vh;
  z-index: 99999;
  background-color: rgba(255, 255, 255, 0.2);
}

.intro-text {
  color: whitesmoke !important;
  font-size: 1.7rem !important;
  font-weight: 600 !important;
  font-family: 'Public Sans', sans-serif !important;
  letter-spacing: 0px;
  max-width: 68.5%;
  margin: auto auto;
  line-break: normal;
  word-wrap: break-word;
}

@media (max-width: 1023px) {
  .intro-text {
    max-width: 100% !important;
  }
}

.title {
  margin-top: 20px !important;
  color: whitesmoke !important;
  font-size: 1.8rem !important;
  font-weight: 800 !important;
  font-family: 'Public Sans', sans-serif !important;
  letter-spacing: 2px;
}

.titleb {
  margin-top: 20px !important;
  color: var(--bg-color) !important;
  font-size: 1.5rem !important;
  font-weight: 800 !important;
  font-family: 'Public Sans', sans-serif !important;
  letter-spacing: 2px;
}
.subtitle {
  margin-top: -13px !important;
  color: whitesmoke !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.title2 {
  margin-top: 0px !important;
  color: whitesmoke !important;
  font-size: 1.8rem !important;
  font-weight: 800 !important;
  font-family: 'Public Sans', sans-serif !important;
  letter-spacing: 2px;
}

.title4 {
  margin-top: 0px !important;
  color: var(--bg-color) !important;
  font-size: 1.8rem !important;
  font-weight: 800 !important;
  font-family: 'Public Sans', sans-serif !important;
  letter-spacing: 2px;
}

.subtitle2 {
  margin-top: 0px !important;
  color: whitesmoke !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  font-family: 'Public Sans', sans-serif !important;
  letter-spacing: 1px;
}

html {
  scroll-padding-top: 100px;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: whitesmoke;
  background-color: var(--bg-color) !important;
  top: 0;
  position: relative;
  margin: 0;
  padding: 0;
  font-family: 'Public Sans', sans-serif;
  min-height: 100%;
  height: 100%;
}
body,
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: whitesmoke;
  top: 0;
  position: relative;
  margin: 0;
  padding: 0;
  font-family: 'Public Sans', sans-serif;
  min-height: 100%;
  background-color: white;
  height: 100%;
}
.logo {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 300px;
  height: 300px;
}
.navbar-brand {
  width: 20% !important;
  padding: 0 !important;
  margin: 0 !important;
}
.navbar-start {
  width: 80% !important;
}

@media (max-width: 1023px) {
  .navbar-brand {
    width: 100% !important;
  }
  .navbar-columns,
  .navbar-start {
    width: 100% !important;
  }
}
.navbar-columns {
  width: 90%;
}
.is-toolbox {
  background-color: white;
  position: relative;
  color: black;
}
.is-toolbox:before {
  content: '';
  display: block;
  padding-top: 45% !important; /*What you want the height to be in relation to the width*/
}
.is-toolbox-wrapper {
  top: 30px !important;
  position: relative;
  display: block;
  margin-left: 40px;
  margin-right: 40px;
}
.tile-title {
  top: -30px;
  color: #242a36 !important;
  font-size: 1.5rem !important;
  font-weight: 800 !important;
  position: relative;
  display: block;
  height: 25px;
  line-height: 25px;
}

.tile-img {
  width: 110px;
  height: 110px;
  display: block;
  position: relative;
  margin: 0 auto;
  top: -50px;
}

.tile-img2 {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.navbar-columns .column {
  text-align: center;
  transition: 0.2s all ease-in-out;
}
.navbar-logo {
  display: block;
  position: relative;
  min-height: 80px;
  min-width: 150px;
  background-color: whitesmoke !important;
  padding: 0 !important;
  margin: 0 !important;
  -webkit-mask: url(./assets/logosoilmates.svg) no-repeat center center;
  mask: url(./assets/logosoilmates.svg) no-repeat center center;
  -webkit-mask-size: 1150% 1150%;
  /* mask-size: 1150% 1150%; */
}
.navbar-logo3 {
  display: block;
  position: relative;
  min-height: 80px;
  min-width: 150px;
  background-color: rgba(36, 43, 55, 1) !important;
  padding: 0 !important;
  margin: 0 !important;
  top: 75px !important;
  -webkit-mask: url(./assets/logosoilmates.svg) no-repeat center center;
  mask: url(./assets/logosoilmates.svg) no-repeat center center;
  -webkit-mask-size: 1500% 1500%;
  /* mask-size: 1500% 1500%; */
}
.navbar-logob {
  display: block;
  position: relative;
  min-height: 80px;
  min-width: 60px;
  background-color: var(--bg-color) !important;
  padding: 0 !important;
  margin: 0;
  left: 50px;
  -webkit-mask: url(./assets/logo_hoveniers.svg) no-repeat center center;
  mask: url(./assets/logo_hoveniers.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
  /* mask-size: 1150% 1150%; */
}
.navbar-logo3b {
  display: block;
  position: relative;
  min-height: 80px;
  min-width: 150px;
  background-color: var(--bg-color) !important;
  padding: 0 !important;
  margin: 0 !important;
  top: 75px !important;
  -webkit-mask: url(./assets/logo_hoveniers.svg) no-repeat center center;
  mask: url(./assets/logo_hoveniers.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
  /* mask-size: 1500% 1500%; */
}
.navbar-logo3d {
  display: block;
  position: relative;
  min-height: 80px;
  min-width: 100px;
  background-color: var(--bg-color) !important;
  padding: 0 !important;
  margin: 0 !important;
  top: 75px !important;
  -webkit-mask: url(./assets/logo_hoveniers.svg) no-repeat center center;
  mask: url(./assets/logo_hoveniers.svg) no-repeat center center;
  -webkit-mask-size: 100% 100%;
  /* mask-size: 1500% 1500%; */
}
.navbar-logo3c {
  display: block;
  position: relative;
  height: 300px;
  width: 150px;
  padding: 0 !important;
  margin: 0 !important;
  top: -60px !important;
  background: url(./assets/boommetzon.png) no-repeat center center;
  background-size: 80%;
}
.contact-details {
  margin-top: 100px !important;
  color: rgba(36, 43, 55, 1) !important;
  font-size: 1.15rem;
  font-weight: 600;
}
.contact-details span {
  padding-left: 10px;
  padding-right: 10px;
}
.contact-details2 {
  margin-top: 25px !important;
  color: whitesmoke !important;
  font-size: 1.15rem;
  font-weight: 600;
  padding-top: 40px;
  background-color: var(--bg-color);
  padding-bottom: 50px;
}
.contact-details2 span {
  padding-left: 10px;
  padding-right: 10px;
}
.contact-detailsb {
  margin-top: 170px !important;
  color: var(--bg-color) !important;
  background-color: var(--bg2) !important;
  font-size: 1.1rem;
  font-weight: 600;
  display: block;
  position: absolute;
  width: 100vw;
  display: block;
  vertical-align: bottom;
  height: 160px;
  /* top: 170px; */
  line-height: 60px;
  /* background-color: red !important; */
}
.contact-detailsbox {
  /* background-color: yellow; */
  display: block;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0;
}
.contact-detailsbox span {
  top: 140px;
}
.contact-detailsb span {
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
}
.contact-detailsb .columns {
  margin-top: 140px !important;
}
.section-footer .columns,
.section-footerb .columns,
.section-contactb .columns {
  max-width: 850px;
  margin: 0 auto;
  /* background-color: red; */
}
.section-contactb .columns {
  max-width: 700px;
  margin: 0 auto;
  /* background-color: red; */
}
.section-footerb .columns .column span {
  font-weight: 400;
}
.section-footer .columns .column,
.section-footerb .columns .column,
.section-contactb .columns .column {
  margin: 0 auto;
  /* border: 2px solid white; */
}
.section-footer .columns .column:nth-child(1),
.section-footerb .columns .column:nth-child(1),
.section-contactb .columns .column:nth-child(1) {
  /* max-width: 160px !important; */
  max-width: 320px !important;
  text-align: right;
}
.section-footer .columns .column:nth-child(2),
.section-footerb .columns .column:nth-child(2),
.section-contactb .columns .column:nth-child(2) {
  /* max-width: 310px !important; */
  text-align: center;
}
.section-contactb .columns .column:nth-child(2) {
  max-width: 230px !important;
}
.section-footer .columns .column:nth-child(3),
.section-footerb .columns .column:nth-child(3),
.section-contactb .columns .column:nth-child(3) {
  /* max-width: 310px !important; */
  max-width: 320px !important;
  text-align: left;
}
@media (max-width: 768px) {
  .box-arjen-staand {
    border: 0 !important;
  }
  .box-arjen-liggend {
    border: 0 !important;
  }
  .box-arjen-staand5 {
    padding-right: 0;
    padding-bottom: 0px;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px !important;
    overflow: hidden !important;
    border: 0px solid white;
  }
  .section-footer .columns .column:nth-child(1),
  .section-footerb .columns .column:nth-child(1),
  .section-contactb .columns .column:nth-child(1) {
    /* max-width: 160px !important; */
    text-align: center !important;
  }
  .section-footer .columns .column:nth-child(3),
  .section-footerb .columns .column:nth-child(3),
  .section-contactb .columns .column:nth-child(3) {
    /* max-width: 310px !important; */
    text-align: center;
    padding-bottom: 50px !important;
  }
}
.section-footer .column span {
  font-weight: 100 !important;
}
.section-footer .column,
.section-contactb .column {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.navbar-logo-full {
  display: block;
  position: relative;
  min-height: 80px;
  min-width: 150px;
  background-color: whitesmoke !important;
  padding: 0 !important;
  margin: 0 !important;
  -webkit-mask: url(./assets/logosoilmates.svg) no-repeat center center;
  mask: url(./assets/logosoilmates.svg) no-repeat center center;
  -webkit-mask-size: 1800% 1800%;
  z-index: 100;
  /* mask-size: 1800% 200%; */
}
.navbar-logo-fullb {
  display: block;
  position: relative;
  min-height: 80px;
  min-width: 90px;
  /* width: 0px; */
  background-color: var(--bg-color) !important;
  padding: 0 !important;
  margin: 0 !important;
  -webkit-mask: url(./assets/logo_hoveniers.svg) no-repeat center center;
  mask: url(./assets/logo_hoveniers.svg) no-repeat center center;
  -webkit-mask-size: 75% 75%;
  z-index: 29;
  /* mask-size: 1800% 200%; */
}
.navbar {
  background-color: var(--bg-color-overlay2) !important;
  /* background-color: rgba(36, 43, 55,0.8) !important; */
  /* backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px); */
  color: whitesmoke !important;
  font-weight: 600;
}
.navbar-menu {
  width: 100% !important;
}
.navbar-start a,
.navbar-burger span {
  color: rgba(245, 245, 245, 0.5);
}
.soilmates-navbarb .navbar-start a {
  color: rgba(245, 245, 245, 0.8);
}
.navbar-burger {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  top: 20px;
  right: 15px;
}
a {
  color: #ab9467;
  cursor: pointer;
  text-decoration: none;
}

a:hover,
a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  color: #7a6b4c !important;
  cursor: pointer;
  text-decoration: none;
}
.soilmates-active,
.soilmates-active a,
.soilmates-active a:hover,
.soilmates-active a:visited,
.soilmates-active a:active,
.soilmates-active a:focus,
.soilmates-active:hover,
.soilmates-active:visited,
.soilmates-active:active,
.soilmates-active:focus {
  font-weight: 800;
  color: rgba(245, 245, 245, 1) !important;
  transition: 0.2s all ease-in-out;
}
.box-arjen-staand5 {
  padding-right: 0;
  padding-bottom: 0px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px !important;
  overflow: hidden !important;
  border: 0px solid white;
}
@media screen and (max-width: 1079px) and (orientation: landscape) {
  .hero-body2 {
    height: 150vh !important;
  }
  .hero2.is-fullheight {
    height: 150vh !important;
    margin-top: -100px;
  }
  .section-category2 {
    margin-top: -90px !important;
  }
  .klantervaringen-imgb {
    height: 100vh !important;
  }
  .box-arjen-staand5 {
    padding-right: 0;
    padding-bottom: 0px;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px !important;
    overflow: hidden !important;
    border: 0px solid white;
  }
  .box-arjen-staand3b {
    padding-right: 0;
    padding-bottom: 0px;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;
    border: 0px solid white;
    height: 200vh !important;
  }
}
@media screen and (max-width: 769px) and (orientation: landscape) {
  .box-arjen-staand5 {
    padding-right: 0;
    padding-bottom: 0px;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden !important;
    border: 0px solid white;
  }
}
</style>
